export default [{
  path: '/brc/index',
  name: 'brcIndex',
  meta: {
    title: '我的',
    keepAlive: false
  },
  component: () => import('@/views/brc/index.vue')
}, {
  path: '/brc/register',
  name: 'brcRegister',
  meta: {
    title: '信息验证',
    keepAlive: false
  },
  component: () => import('@/views/brc/register.vue')
}, {
  path: '/brc/reservation/info',
  name: 'brcReservationInfo',
  meta: {
    title: '预约',
    keepAlive: false
  },
  component: () => import('@/views/brc/reservation-info.vue')
}, {
  path: '/brc/reservation/question',
  name: 'brcReservationQuestion',
  meta: {
    title: '调查问卷',
    keepAlive: false
  },
  component: () => import('@/views/brc/reservation-question.vue')
}, {
  path: '/brc/reservation/end',
  name: 'brcReservationEnd',
  meta: {
    title: '预约',
    keepAlive: false
  },
  component: () => import('@/views/brc/reservation-end.vue')
}, {
  path: '/brc/reservation/result',
  name: 'brcReservationResult',
  meta: {
    title: '预约结果',
    keepAlive: false
  },
  component: () => import('@/views/brc/reservation-result.vue')
}, {
  path: '/brc/equity/list',
  name: 'brcEquityList',
  meta: {
    title: '可用权益',
    keepAlive: false
  },
  component: () => import('@/views/brc/equity-list.vue')
}, {
  path: '/brc/my/reservation',
  name: 'brcMyReservation',
  meta: {
    title: '我的预约',
    keepAlive: false
  },
  component: () => import('@/views/brc/my-reservation.vue')
}, {
  path: '/brc/my/report',
  name: 'brcMyReport',
  meta: {
    title: '健康报告',
    keepAlive: false
  },
  component: () => import('@/views/brc/my-report.vue')
}];