import request from '@/utils/request';
export function fileUpload(type, query, sucFun) {
  //上传
  request.post(`/healthbase/upload/file?type=${type}`, query, sucFun);
}
export function fileUploadBase64(query, sucFun) {
  //上传
  request.post(`/healthbase/upload/file/base64`, query, sucFun);
}
export function getQiniuToken(query, sucFun) {
  //获取七牛token
  request.post(`/healthbase/upload/qiniu/query/upload/token?uploadType=${query.uploadType}`, query, sucFun);
}

//获取微信jssdkurl签名
export function getJsApiSign(appId, query, sucFun) {
  request.getNoLoaded(`/wxmp/jsapiSign/${appId}`, query, sucFun);
}

//字典表
export function getCodeTable(label, query, sucFun) {
  request.getNoLoaded(`/resource/codeValue/condition/${label}`, query, sucFun);
}