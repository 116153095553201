export default [{
  path: '/works',
  name: 'works',
  component: () => import('@/views/works/index'),
  meta: {
    title: '作品活动主页',
    keepAlive: true
  }
}, {
  path: '/works/upload',
  name: 'worksUpload',
  component: () => import('@/views/works/upload'),
  meta: {
    title: '上传作品',
    keepAlive: true
  }
}, {
  path: '/works/detail',
  name: 'worksDetail',
  component: () => import('@/views/works/detail'),
  meta: {
    title: '作品详情',
    keepAlive: true
  }
}, {
  path: '/works/myWorks',
  name: 'MyWorks',
  component: () => import('@/views/works/myWorks'),
  meta: {
    title: '我的作品',
    keepAlive: true
  }
}, {
  path: '/works/auditList',
  name: 'auditList',
  component: () => import('@/views/works/auditList'),
  meta: {
    title: '作品审核列表',
    keepAlive: true
  }
}, {
  path: '/works/auditDetail',
  name: 'auditDetail',
  component: () => import('@/views/works/auditDetail'),
  meta: {
    title: '作品审核详情',
    keepAlive: true
  }
}, {
  path: '/works/preview',
  name: 'worksPreview',
  component: () => import('@/views/works/preview'),
  meta: {
    title: '作品预览',
    keepAlive: true
  }
}];