import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import router from '../../router/index';
export default {
  name: "registerDialog",
  data() {
    return {
      isShow: false,
      userType: '',
      agencyInfo: ''
    };
  },
  methods: {
    goRegister() {
      this.isShow = false;
      let routerPath;
      let query;
      if (this.userType) {
        query = {
          userType: this.userType,
          agencyInfo: this.agencyInfo
        };
        routerPath = {
          path: '/physical/register',
          query
        };
      } else {
        routerPath = {
          path: '/physical/register'
        };
      }
      router.push(routerPath);
    }
  }
};