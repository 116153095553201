//
//
//
//
//
//
//
//

import Loading from '@/components/Loading.vue';
export default {
  name: 'App',
  components: {
    Loading
  },
  created() {
    if (this.apiObj.isIos) {
      sessionStorage.setItem('entryUrl', window.location.href.split('#')[0]);
    }
  }
};