import "vant/es/nav-bar/style";
import _NavBar from "vant/es/nav-bar";
import "vant/es/button/style";
import _Button from "vant/es/button";
import "vant/es/popup/style";
import _Popup from "vant/es/popup";
import "vant/es/count-down/style";
import _CountDown from "vant/es/count-down";
import "vant/es/checkbox/style";
import _Checkbox from "vant/es/checkbox";
import "vant/es/field/style";
import _Field from "vant/es/field";
import "vant/es/form/style";
import _Form from "vant/es/form";
import { smsCode, register } from "@/api/user";
import globalFunction from '../../utils/globalFunction';
export default {
  name: "LoginDialog",
  components: {
    [_Form.name]: _Form,
    [_Field.name]: _Field,
    [_Checkbox.name]: _Checkbox,
    [_CountDown.name]: _CountDown,
    [_Popup.name]: _Popup,
    [_Button.name]: _Button,
    [_NavBar.name]: _NavBar
  },
  props: {},
  data() {
    return {
      show: true,
      color: '#152E5C',
      time: 60 * 1000,
      second: 60,
      submitParams: {
        mobile: '',
        smsCode: '',
        agentCode: '0',
        registerType: '01'
      },
      isInSecond: false,
      checked: false,
      userProtocol: false,
      dataProtocol: false
    };
  },
  created() {},
  methods: {
    validator(val) {
      return /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(val);
    },
    validator1(val) {
      return this.globalFunction.smsCodeReg.test(val);
    },
    onSubmit() {
      if (!this.submitParams.smsCode) {
        this.$toast('请输入验证码');
        return;
      }
      if (!this.checked) {
        this.$toast('请阅读并同意用户协议和隐私政策');
        return;
      }
      register(this.submitParams, json => {
        if (json.code == 200) {
          if (json.data.token) {
            localStorage.setItem("userToken", json.data.token);
            Vue.prototype.apiObj.token = json.data.token;
            this.show = false;
            sessionStorage.removeItem('userInfo');
            globalFunction.goAgentInfo(data => {
              this.login(data);
            });
          }
        } else {
          this.$toast(json.message);
        }
      });
    },
    countDownFinish() {
      this.isInSecond = false;
      this.$forceUpdate();
    },
    getSmsCode() {
      if (!this.submitParams.mobile) {
        this.$toast('请输入手机号');
        return;
      }
      let params = {
        mobile: this.submitParams.mobile,
        smsCodeType: '1'
      };
      smsCode(params, json => {
        if (json.code == 200) {
          this.$toast('验证码发送成功');
          this.isInSecond = true;
          this.$forceUpdate();
        } else {
          this.$toast('验证码发送失败，请稍后重试');
        }
      });
    }
  }
};