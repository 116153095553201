import "core-js/modules/es.array.push.js";
/**
 * Created by wzy on 2018/12/07.
 *
 */

import { loginCheck, getUserAgent } from "@/api/user";
import { getGuardIndex, shareSetting } from "@/api/publicApi";
import { getJsApiSign } from "@/api/utils/index";
import globalConfig from '@/config';
export default {
  nameReg: /^[a-zA-Z\u4e00-\u9fa5]{2,20}$/,
  // 姓名正则
  mobileReg: /^1\d{10}$/,
  // 手机号正则
  emailReg: /^([a-z0-9A-Z]+[_\-|\.]?)+[a-z0-9A-Z]@([a-z0-9A-Z]+(-[a-z0-9A-Z]+)?\.)+[a-zA-Z]{2,}$/,
  // 邮箱正则
  smsCodeReg: /^[a-z0-9A-Z]{6}$/,
  // 验证码 密码正则
  bankAccountReg: /^([1-9]{1})(\d{9,18})$/,
  // 银行卡号正则
  numberReg: /^[0-9]*$/,
  //数字正则
  weightReg: /^(0(\.\d{1}){0,1}|[1-8]\d{1,3}(\.\d{1}){0,1}|9\d{1,2}(\.\d{1}){0,1}|999(\.0){0,1}|.{0})$/,
  //体重正则
  isTrue: true,
  isFalse: false,
  myFor(arr, callback) {
    for (let i = 0, len = arr.length; i < len; i++) {
      callback(arr[i], i);
    }
  },
  /**
   * 数值按照指定位数补零
   * @params num:原数值
   * @params index:补零后位数，如不传，则默认为2位 必须number类型
   */
  getZero(num, index = 2) {
    if (!num && num != 0 || isNaN(num)) return num;
    if (typeof num === 'string' && num != 0) {
      num = Number(num);
    }
    if (num < 0) {
      return '暂不支持负数';
    }
    for (var i = 1; i < index; i++) {
      if (num == 0) {
        num += '0';
      } else if (num > 0) {
        if (Math.floor(num) < Math.pow(10, i)) {
          num = '0' + num;
        }
      }
    }
    return num;
  },
  /**
   * 对传入金额转换格式
   * @param amount 数字字符串或者数字 '5000' 10000
   * @return 5千 1万
   */
  formatAmount(amount) {
    amount = +amount;
    if (amount >= 10000) {
      return Math.floor(amount / 10000) + '万';
    } else if (amount > 1000) {
      return Math.floor(amount / 1000) + '千';
    } else {
      return amount + '元';
    }
  },
  /**
   * 数值保留小数点后指定位数
   * @params num:原数值
   * @params index:保留小数点位数，如不传，则默认为2位 必须number类型
   */
  getFloatNum(num, index = 2) {
    if (!num && num != 0 || isNaN(num)) return num;
    if (typeof num === 'string') {
      num = Number(num);
    }
    return num.toFixed(index);
  },
  /**
   * 时间戳转换成日期
   * @params date:时间戳
   * @params days:指定延后天数 默认0
   * @params fmt:格式，如不传，则默认为yyyy-mm-dd格式
   */
  getFormatDate(date, days = 0, fmt = 'yyyy-mm-dd') {
    if (!date) return false;
    let time = new Date(Math.floor(date) + 86400000 * days),
      year = time.getFullYear(),
      month = this.getZero(time.getMonth() + 1),
      day = this.getZero(time.getDate()),
      hour = this.getZero(time.getHours()),
      minte = this.getZero(time.getMinutes()),
      second = this.getZero(time.getSeconds()),
      res = '';
    switch (fmt) {
      case 'yyyy-mm-dd':
        return `${year}-${month}-${day}`;
        break;
      case 'yyyy-mm-dd hh:mm:ss':
        return `${year}-${month}-${day} ${hour}:${minte}:${second}`;
        break;
      case 'yyyy-mm-dd hh:mm':
        return `${year}-${month}-${day} ${hour}:${minte}`;
        break;
      case 'yyyy-mm':
        return `${year}-${month}`;
        break;
      case 'yyyy:mm:dd hh:mm:ss':
        return `${year}:${month}:${day} ${hour}:${minte}:${second}`;
        break;
      case 'yyyymmddhhmmss':
        return `${year}${month}${day}${hour}${minte}${second}`;
        break;
      case 'yyyy:mm:dd hh:mm':
        return `${year}:${month}:${day} ${hour}:${minte}`;
        break;
      case 'yyyy:mm:dd':
        return `${year}:${month}:${day}`;
        break;
      case 'yyyy:mm':
        return `${year}:${month}`;
        break;
      case 'yyyy.mm':
        return `${year}.${month}`;
        break;
      case 'yyyy.mm.dd':
        return `${year}.${month}.${day}`;
        break;
      case 'yyyy.mm.dd hh:mm':
        return `${year}.${month}.${day} ${hour}:${minte}`;
        break;
      case 'yyyymmdd':
        return `${year}${month}${day}`;
        break;
    }
  },
  /**
   * 获取随机字符串
   * @param length 要获取的字符串的长度
   * @return 指定长度的随机字符串
   */
  randomStr(length) {
    let str = Math.random().toString(36).substr(2);
    if (str.length > length) return str.substr(0, length);
    str += this.randomStr(length - str.length);
    return str;
  },
  /**
   * 接口签名方法
   * @params url 除域名外的请求地址
   * @params type 请求类型
   * @params params 请求参数
   * @return 签名后的header
   */
  signature(header, url, type, params) {
    let params_copy = JSON.parse(JSON.stringify(params));
    let index = url.indexOf('?');
    if (index >= 0) {
      let paramValue = url.substr(index + 1);
      url = url.substr(0, index);
      this.myFor(paramValue.split('&'), (item, index) => {
        let param = item.split('=');
        params_copy[param[0]] = param[1].toString();
      });
    }
    let signatureJson = {
        device: native.getAppDevice(),
        nonce: this.randomStr(16),
        version: native.getAppVersion(),
        secret: 'AeE1Go2',
        timestamp: this.getFormatDate(Date.now(), 0, 'yyyymmddhhmmss'),
        token: header.token,
        clientId: header.clientId,
        requestUri: '/' + url
      },
      sign;
    if (type == 'GET' && params_copy) {
      signatureJson = {
        ...signatureJson,
        ...params_copy
      };
    }
    if (type == 'POST' && params_copy) {
      signatureJson.body = JSON.stringify(params_copy);
    }
    sign = native.getAppSign(JSON.stringify(signatureJson));
    header.sign = sign;
    header.nonce = signatureJson.nonce;
    header.timestamp = signatureJson.timestamp;
    header.device = signatureJson.device;
    header.version = signatureJson.version;
    header.secret = signatureJson.secret;
    return header;
  },
  // 支付密码MD5加密
  md5(str) {
    return hex_md5(hex_md5(str).substring(8, 24)).substring(8, 24);
  },
  /**
   * 验证18位数身份证号码中的生日是否是有效生日
   * @param idCardNo 18位书身份证字符串
   * @return
   */
  isValidityIdCardNo18(idCardNo) {
    let year = idCardNo.substring(6, 10),
      month = idCardNo.substring(10, 12),
      day = idCardNo.substring(12, 14),
      date = new Date(year, parseFloat(month) - 1, parseFloat(day));
    // 这里用getFullYear()获取年份，避免千年虫问题
    if (date.getFullYear() != parseFloat(year) || date.getMonth() != parseFloat(month) - 1 || date.getDate() != parseFloat(day)) {
      return false;
    }
    return true;
  },
  /**
   * 验证15位数身份证号码中的生日是否是有效生日
   * @param idCardNo 15位书身份证字符串
   * @return
   */
  isValidityIdCardNo15(idCardNo) {
    let year = idCardNo.substring(6, 8),
      month = idCardNo.substring(8, 10),
      day = idCardNo.substring(10, 12),
      date = new Date(year, parseFloat(month) - 1, parseFloat(day));
    // 对于老身份证中的你年龄则不需考虑千年虫问题而使用getYear()方法
    if (date.getYear() != parseFloat(year) || date.getMonth() != parseFloat(month) - 1 || date.getDate() != parseFloat(day)) {
      return false;
    }
    return true;
  },
  /**
   * 判断身份证号码为18位时最后的验证位是否正确
   * @param idCardNoArr 身份证号码数组
   * @return
   */
  IdCardValidateFor18(idCardNoArr) {
    let Wi = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2, 1],
      // 加权因子
      ValideCode = [1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2],
      // 身份证验证位值.10代表X
      sum = 0; // 声明加权求和变量
    if (idCardNoArr[17].toLowerCase() == 'x') {
      idCardNoArr[17] = 10; // 将最后位为x的验证码替换为10方便后续操作
    }

    for (let i = 0; i < 17; i++) {
      sum += Wi[i] * idCardNoArr[i]; // 加权求和
    }

    let valCodePosition = sum % 11; // 得到验证码所位置
    if (idCardNoArr[17] == ValideCode[valCodePosition]) {
      return true;
    }
    return false;
  },
  /**
   * 验证身份证证件号
   * @param idCardNo 身份证号码
   * @return
   */
  IdCardValidate(idCardNo) {
    if (!idCardNo) return false;
    if (idCardNo.length == 15) {
      //进行15位身份证的验证
      return this.isValidityIdCardNo15(idCardNo);
    } else if (idCardNo.length == 18) {
      //进行15位身份证的验证
      let idCardNoArr = idCardNo.split(''); // 得到身份证数组
      if (this.isValidityIdCardNo18(idCardNo) && this.IdCardValidateFor18(idCardNoArr)) {
        //进行18位身份证的基本验证和第18位的验证
        return true;
      }
      return false;
    } else {
      return false;
    }
  },
  // 获取生日
  getBirthdayFromIdCard(idCard) {
    //根据身份证计算生日格式1997-00-00
    var birthday = "";
    if (idCard != null && idCard != "") {
      if (idCard.length == 15) {
        birthday = "19" + idCard.substr(6, 6);
      } else if (idCard.length == 18) {
        birthday = idCard.substr(6, 8);
      }
      birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");
    }
    return birthday;
  },
  // 获取性别
  getSexFromIdCard(idCard) {
    var sex = "";
    if (idCard != null && idCard != "") {
      if (idCard.length == 18) {
        sex = idCard.substring(16, 17);
      } else if (idCard.length == 15) {
        sex = idCard.substring(13, 14);
      }
      if (sex % 2 === 0) sex = "2"; // 性别代码 1代表男，2代表女，暂时不涉及其他类型性别
      else sex = "1";
      return sex;
    }
  },
  /**
   * 对上传图片进行压缩
   * */
  getImgBase64(file, quality = 1, resolve, reject) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      let result = e.target.result;
      // 实例化一个img对象并赋值src
      let img = new Image();
      img.src = result;
      // 获取上传图片的文件大小
      let imgSize = file.size / 1024;
      console.log('----');
      console.log(imgSize);
      if (imgSize > 200) {
        //大于200k进行压缩
        img.onload = function () {
          // 图片原始尺寸，压缩后最大宽高，canvas宽高，尺寸压缩比例
          let w = this.width,
            h = this.height,
            maxWidth = 1500,
            maxHeight = 2000,
            canW,
            canH,
            proportion;
          // 判断图片尺寸是否大于限定尺寸
          if (w > maxWidth || h > maxHeight) {
            if (w > h) {
              // 图片是宽大于高的
              canW = maxWidth;
              proportion = w / maxWidth;
              canH = h / proportion;
            } else if (w < h) {
              // 图片是宽大于高的
              canH = maxHeight;
              proportion = h / maxHeight;
              canW = w / proportion;
            } else {
              canW = w;
              canH = h;
            }
          } else {
            canW = w;
            canH = h;
          }
          let canvas = document.createElement('canvas');
          let ctx = canvas.getContext('2d');
          // 创建属性节点
          let anw = document.createAttribute('width');
          anw.nodeValue = canW;
          let anh = document.createAttribute('height');
          anh.nodeValue = canH;
          canvas.setAttributeNode(anw);
          canvas.setAttributeNode(anh);
          ctx.drawImage(this, 0, 0, canW, canH);
          let result = canvas.toDataURL('image/jpeg', quality);
          resolve([result, parseInt(canW), parseInt(canH)]);
        };
      } else {
        img.onload = function () {
          let canW = this.width,
            canH = this.height;
          resolve([result, parseInt(canW), parseInt(canH)]);
        };
      }
    };
  },
  //去掉字符串头尾空格
  trim(str) {
    return str.replace(/(^\s*)|(\s*$)/g, '');
  },
  /**
   * 生成GUID
   * */
  Mathiserial: function () {
    return new Date().getTime().toString() + '-adbh5xxxxxxxxxxxx9xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : r & 0x3 | 0x8;
      return v.toString(16);
    });
  },
  /**
   * 生成UUID
   * */
  Mathuuid: function (len, radix) {
    var CHARS = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
    var chars = CHARS,
      uuid = [],
      i;
    radix = radix || chars.length;
    if (len) {
      // Compact form
      for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
    } else {
      // rfc4122, version 4 form
      var r;

      // rfc4122 requires these characters
      uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
      uuid[14] = '4';

      // Fill in random data.  At i==19 set the high bits of clock sequence as
      // per rfc4122, sec. 4.1.5
      for (i = 0; i < 36; i++) {
        if (!uuid[i]) {
          r = 0 | Math.random() * 16;
          uuid[i] = chars[i == 19 ? r & 0x3 | 0x8 : r];
        }
      }
    }
    return uuid.join('');
  },
  /**
   * 获取链接参数
   * */
  getQueryString(name) {
    var src = window.location.href.substring(window.location.href.indexOf('?') + 1);
    var reg = new RegExp('(^|&|/?)' + name + '=([^&]*)(&|$)');
    var r = src.match(reg);
    if (r != null) return decodeURIComponent(r[2]);
    return null;
  },
  /**
   * 给url添加参数 如：http://www.tr.com?name=tr&age=4
   */
  addUrlQuery(url, obj) {
    if (!obj || Object.keys(obj).length == 0) return url;
    let encodeKey, encodeValue;
    for (let key in obj) {
      encodeKey = encodeURIComponent(key);
      encodeValue = encodeURIComponent(obj[key]);
      if (url.indexOf('?') != -1) {
        url += encodeURI(`&${encodeKey}=${encodeValue}`);
      } else {
        url += encodeURI(`?${encodeKey}=${encodeValue}`);
      }
    }
    return url;
  },
  /**
   * 获取字符串中某字符 第n次 出现的位置角标
   * @param str 整个字符串
   * @param cha 某字符
   * @param num 第几次出现 从1开始
   */
  getNthLocationIndex(str, cha, num) {
    let index = str.indexOf(cha);
    for (let i = 1; i < num; i++) {
      index = str.indexOf(cha, index + 1);
    }
    return index;
  },
  /**
   * 获取截取的字符串（包头不包尾）
   * @param str 整个字符串
   * @param starIndex 开始角标
   * @param endIndex 结束角标
   */
  getSubStringStr(str, starIndex, endIndex) {
    return str.substring(starIndex, endIndex);
  },
  /**
   * 函数防抖
   * 触发事件后在n秒后执行，如果n秒内又触发事件，则重新计算时间
   */
  debounce(fn, wait = 800) {
    let timer;
    return function () {
      let context = this;
      let args = arguments;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, wait);
    };
  },
  /**
   * 函数节流
   * 触发事件立即执行，但在n秒内连续触发则不执行
   */
  throttle(fn, wait = 1000) {
    let timer;
    return function () {
      if (timer != null) return;
      let context = this;
      let args = arguments;
      fn.apply(context, args);
      timer = setTimeout(() => {
        timer = null;
      }, wait);
    };
  },
  /**
   * 路由跳转
   */
  jumpRouter(path, query) {
    this.$router.push({
      path,
      query
    });
  },
  /**
   * 跳转App内打开
   */
  jumpNativePage(url) {
    native.jsCallWebView(url);
  },
  /**
   * 根据平台跳转
   */
  jumpDiffPlatform(path, query, isWx) {
    if (isWx) {
      this.globalFunction.jumpRouter.call(this, path, query);
    } else {
      let newUrl;
      if (path.indexOf('http') !== -1) {
        newUrl = path;
      } else {
        newUrl = `${this.apiObj.apiPath}#${this.globalFunction.addUrlQuery(path, query)}`;
      }
      this.globalFunction.jumpNativePage(newUrl);
    }
  },
  /**
   * 序列化反序列化法
   */
  deepClone(obj) {
    return JSON.parse(JSON.stringify(obj));
  },
  /**
   * 创建冻结对象-创建后对象内属性不可修改
   * 组件中shareConfig不需要在data中定义，直接通过this.shareConfig创建就可以
   * @param link 分享url
   * @param imgUrl 分享图片
   * @param title 分享标题
   * @param desc 分享描述
   */
  createFreezeObj(link = '', imgUrl = '', title = '', desc = '') {
    return Object.freeze({
      link,
      imgUrl,
      title,
      desc
    });
  },
  /**
   * 查询当前用户权益
   */
  getUserRole(parentThis, callback) {
    let roleData = sessionStorage.getItem("roleData");
    if (roleData) {
      roleData = JSON.parse(roleData);
      callback(roleData);
    } else {
      parentThis.axios.get(`healthaccount/HealthUserRole/queryAgentRole`, {
        userOpenId: parentThis.apiObj.userOpenId
      }, json => {
        if (json.code == 200 && json.data && json.data.length > 0) {
          sessionStorage.setItem("roleData", JSON.stringify(json.data));
          callback(json.data);
        } else {
          callback('');
        }
      }, null, false);
    }
  },
  /**
   * Object对象长度
   */
  getObjectLength(obj) {
    var count = 0;
    for (var i in obj) {
      if (obj.hasOwnProperty(i)) {
        //如果包含除它的原型本身之外的属性
        count++;
      }
      ;
    }
    ;
    return count;
  },
  /**
   * json转换为geUrl的参数
   */
  jsonToUrlParams(params) {
    let s = '';
    for (let i in params) {
      s = s + '&' + i + '=' + params[i];
    }
    s = s.substring(1);
    return s;
  },
  /**
   * 登录方法
   */
  goLogin() {
    let urlParams = location.href.split('#/')[1].split('?');
    let url = urlParams[0].replace(/\//g, '_');
    let hrefParams = location.href.split('?')[1];
    let params = '';
    let targetUrl = '';
    if (hrefParams != undefined) {
      params = hrefParams.replace(/\&/g, '**');
      targetUrl = encodeURI(`${globalConfig.apiPath}/static/wxIndex.html?params=${url}**${hrefParams}`);
    } else {
      targetUrl = encodeURI(`${globalConfig.apiPath}/static/wxIndex.html?params=${url}`);
    }
    if (globalConfig.isWeixin) {
      var num = 0;
      if (localStorage.getItem('visitNumber')) {
        num = parseInt(localStorage.getItem('visitNumber'));
        if (num >= 3) {
          localStorage.removeItem('visitNumber');
          //wx.closeWindow();
          return false;
        }
      }
      if (!globalConfig.token || !localStorage.getItem('userToken')) {
        localStorage.setItem('visitNumber', num + 1);
        if (process.env.NODE_ENV == 'test' || process.env.NODE_ENV == 'uat' || process.env.NODE_ENV == 'yby' || process.env.NODE_ENV == 'product') {
          location.href = `${globalConfig.apiPath}/wxmp/codeinfo/${globalConfig.clientId}/login?targetUrl=${targetUrl}`;
        }
      } else {
        localStorage.removeItem('visitNumber');
      }
    } else {
      /*login({
          'openId': globalConfig.userOpenId
      },(response)=>{
          if(response.code=='200'){
              console.info(response);
              localStorage.setItem('userToken', response.data.token);
              Vue.prototype.apiObj.token = response.data.token;
              localStorage.setItem('userInfo',JSON.stringify(response.data));
          }
      })*/
    }
  },
  /**
   * 获取用户信息
   */
  goAgentInfo(callback) {
    if (sessionStorage.getItem('userInfo')) {
      if (callback) {
        callback(JSON.parse(sessionStorage.getItem('userInfo')));
      }
    } else {
      loginCheck({}, response => {
        if (response.code == '200') {
          console.info('loginCheck', response);
          sessionStorage.setItem('userInfo', JSON.stringify(response.data));
          if (callback) {
            callback(response.data);
          }
        } else {}
      });
    }
  },
  /**
   * 获取指定用户对应服务人员信息
   */
  getUserAgent() {
    return new Promise((resolve, reject) => {
      getUserAgent({}, json => {
        if (json.code == 200) {
          resolve(json.data);
        } else {
          reject(json.message);
        }
      });
    });
  },
  /**
   * 获取守护值和健康豆
   */
  goGuardHealthBean(callback) {
    let urlParams = location.href.split('#/')[1].split('?');
    let url = urlParams[0].replace(/\//g, '_');
    let hrefParams = location.href.split('?')[1];
    let params = '';
    let targetUrl = '';
    if (hrefParams != undefined) {
      params = hrefParams.replace(/\&/g, '**');
      targetUrl = encodeURI(`${globalConfig.apiPath}/static/wxIndex.html?params=${url}**${hrefParams}`);
    } else {
      targetUrl = encodeURI(`${globalConfig.apiPath}/static/wxIndex.html?params=${url}`);
    }
    if (globalConfig.token) {
      getGuardIndex({}, response => {
        if (response.code == '200') {
          console.info('guardHealBean', response);
          localStorage.setItem('guardHealBean', JSON.stringify(response.data));
          if (callback) {
            callback(response.data);
          }
        } else {
          location.href = `${globalConfig.apiPath}/wxmp/codeinfo/${globalConfig.clientId}/login?targetUrl=${targetUrl}`;
        }
      });
    } else {
      location.href = `${globalConfig.apiPath}/wxmp/codeinfo/${globalConfig.clientId}/login?targetUrl=${targetUrl}`;
    }
  },
  /**
   * 浮动按钮-咨询客服
   */
  seekService(name) {
    let userOpenId = globalConfig.userOpenId || Date.now() + Math.round(Math.random() * 100000000).toString();
    let userName = name ? name : globalConfig.userOpenId;
    let sourceName = 'tryf';
    let accessId = 'a4f15710-7cca-11eb-8c08-cd35168e6352';
    location.href = `http://webchat.yuntongxun.com/wapchat.html?accessId=${accessId}&fromUrl=WeiXin&clientId=${userOpenId}&otherParams={"nickName":"${userName}"} `;
  },
  /**
   * 分享方法，不要使用call方法，需要传递this进来
   * 写产品时定义的方法，直接复用吧
   * shareConfig不需要在data中定义，直接动态创建并且不管微信还是原生都需要初始化
   */
  shareWxInit(parentThis, successCallback, failureCallback) {
    if (this.getQueryString('taskId')) parentThis.shareConfig.link = parentThis.shareConfig.link + '&taskId=' + this.getQueryString('taskId');
    if (parentThis.apiObj.isWeixin) {
      let _this = this;
      let signUrl = '';
      //安卓和ios做不同处理,ios需要做特殊处理
      if (parentThis.apiObj.isIos) {
        signUrl = sessionStorage.getItem('entryUrl');
      } else {
        signUrl = location.href;
      }
      getJsApiSign(parentThis.apiObj.clientId, {
        url: encodeURI(signUrl)
      }, json => {
        if (json.code == 200) {
          wx.config({
            debug: false,
            appId: json.data.appId,
            timestamp: json.data.timestamp,
            nonceStr: json.data.nonceStr,
            signature: json.data.signature,
            jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage', 'hideMenuItems', 'showMenuItems', 'previewImage'],
            openTagList: ['wx-open-launch-weapp']
          });
          wx.ready(function () {
            wx.checkJsApi({
              jsApiList: ['onMenuShareTimeline',
              // 朋友圈
              'onMenuShareAppMessage',
              // 朋友
              'hideMenuItems', 'showMenuItems', 'previewImage'],
              success: function (res) {}
            });
            wx.onMenuShareAppMessage({
              title: parentThis.shareConfig.title,
              // 分享标题
              link: parentThis.shareConfig.link,
              // 分享链接,将当前登录用户转为puid,以便于发展下线
              imgUrl: parentThis.shareConfig.imgUrl,
              // 分享图标
              desc: parentThis.shareConfig.desc,
              //分享描述
              success: function () {
                if (successCallback) {
                  successCallback('1');
                }
              },
              cancel: function () {
                // 用户取消分享后执行的回调函数
                if (failureCallback) {
                  failureCallback('1');
                }
              }
            });
            wx.onMenuShareTimeline({
              title: parentThis.shareConfig.title,
              // 分享标题
              link: parentThis.shareConfig.link,
              // 分享链接,将当前登录用户转为puid,以便于发展下线
              imgUrl: parentThis.shareConfig.imgUrl,
              // 分享图标
              desc: parentThis.shareConfig.desc,
              //分享描述
              success: function () {
                if (successCallback) {
                  successCallback('2');
                }
              },
              cancel: function () {
                // 用户取消分享后执行的回调函数
                if (failureCallback) {
                  failureCallback('2');
                }
              }
            });
          });
        } else {
          parentThis.$toast(json.message);
        }
      }, err => {
        parentThis.$toast('微信分享接口调用失败');
      });
    }
  },
  /**
   * 单独获取分享信息方法
   */
  shareSettingFn(relationId) {
    return new Promise((resolve, reject) => {
      shareSetting(relationId, {}, response => {
        if (response.code == '200') {
          resolve(response.data);
        } else {
          reject(response.message);
        }
      });
    });
  }
};