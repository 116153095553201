export default [{
  path: '/cure/zytzIntroduce',
  name: 'zytzIntroduce',
  meta: {
    title: '中医体质辨识',
    keepAlive: false
  },
  component: () => import('@/views/health/cure/zytz-introduce.vue')
}, {
  path: '/cure/question',
  name: 'cureQuestion',
  meta: {
    title: '问卷填写',
    keepAlive: false
  },
  component: () => import('@/views/health/cure/question.vue')
}, {
  path: '/cure/bodyChart',
  name: 'cureBodyChart',
  meta: {
    title: '中医体质辨识报告',
    keepAlive: false
  },
  component: () => import('@/views/health/cure/bodyChart.vue')
}, {
  path: '/cure/expert',
  name: 'cureExpert',
  meta: {
    title: '中医体质辨识专家说法',
    keepAlive: false
  },
  component: () => import('@/views/health/cure/expert.vue')
}, {
  path: '/cure/demo',
  name: 'cureDemo',
  meta: {
    title: '样例',
    keepAlive: false
  },
  component: () => import('@/views/health/cure/demo.vue')
}, {
  path: '/health/home/index',
  name: 'healthHomeIndex',
  meta: {
    title: '健康账户',
    keepAlive: false
  },
  component: () => import('@/views/health/home/index.vue')
}, {
  path: '/clearStorage',
  name: 'clearStorage',
  meta: {
    title: '清缓存',
    keepAlive: false
  },
  component: () => import('@/views/clearStorage.vue')
}];