// 兼容 IE
// https://github.com/zloirock/core-js/blob/master/docs/2019-03-19-core-js-3-babel-and-a-look-into-the-future.md#babelpolyfill
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import App from './App.vue';
import router from './router/index';
import store from './store/index';
import axios from './utils/request';
import waterfall from 'vue-waterfall2';
Vue.use(waterfall);
// 引入封装后的axios并绑定到Vue原型
Vue.prototype.axios = axios;
import utils from './utils/utils';
// 配置路由keepAlive状态
utils.setRouterBeforeEach(router);

// 全局引入按需引入UI库 vant
import './plugins/vant';
// 引入全部样式
import 'vant/lib/index.less';
import './assets/css/vantChange.less';
import '@/icons';

// 引入全局样式
import '@/assets/css/common.less';
import './utils/rem';

// 移动端适配
// import 'lib-flexible/flexible.js'
import './utils/rem';

// filters
import './filters';
Vue.config.productionTip = false;
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
    // 解决ios无法获取动态修改的title
    if (navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
      const _iframe = document.createElement('iframe');
      _iframe.style.display = 'none';
      document.body.appendChild(_iframe);
      setTimeout(() => {
        document.body.removeChild(_iframe);
      }, 300);
    }
  }
  next();
});
import globalFunction from './utils/globalFunction';
globalFunction.goLogin();
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
});