import "core-js/modules/es.array.push.js";
const state = {
  activatedReloadData: true,
  // 页面activated时是否需要重新加载
  // 缓存的路由列表
  cachedRouteNames: []
};
const mutations = {
  UPDATECACHEDROUTENAMES(st, {
    action,
    route
  }) {
    const methods = {
      add: () => {
        console.info('add', st);
        st.cachedRouteNames.push(route);
      },
      delete: () => {
        console.info("delete", st);
        st.cachedRouteNames.splice(st.cachedRouteNames.findIndex(e => e === route), 1);
      }
    };
    methods[action]();
  }
};
export default {
  namespaced: true,
  state,
  mutations
};