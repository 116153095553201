import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "vant/es/icon/style";
import _Icon from "vant/es/icon";
import "vant/es/list/style";
import _List from "vant/es/list";
import "vant/es/cell/style";
import _Cell from "vant/es/cell";
import "vant/es/button/style";
import _Button from "vant/es/button";
import "vant/es/tabbar/style";
import _Tabbar from "vant/es/tabbar";
import "vant/es/tabbar-item/style";
import _TabbarItem from "vant/es/tabbar-item";
import "vant/es/loading/style";
import _Loading from "vant/es/loading";
import "vant/es/search/style";
import _Search from "vant/es/search";
import "vant/es/swipe/style";
import _Swipe from "vant/es/swipe";
import "vant/es/swipe-item/style";
import _SwipeItem from "vant/es/swipe-item";
import "vant/es/tab/style";
import _Tab from "vant/es/tab";
import "vant/es/tabs/style";
import _Tabs from "vant/es/tabs";
import "vant/es/progress/style";
import _Progress from "vant/es/progress";
import "vant/es/notice-bar/style";
import _NoticeBar from "vant/es/notice-bar";
import "vant/es/field/style";
import _Field from "vant/es/field";
import "vant/es/picker/style";
import _Picker from "vant/es/picker";
import "vant/es/popup/style";
import _Popup from "vant/es/popup";
Vue.use(_Button).use(_List).use(_Cell).use(_Button).use(_Tabbar).use(_TabbarItem).use(_Loading).use(_Search).use(_Swipe).use(_SwipeItem).use(_Tab).use(_Tabs).use(_Progress).use(_NoticeBar).use(_Field).use(_Picker).use(_Popup);
Vue.use(_Tabbar).use(_TabbarItem).use(_Loading).use(_Icon);
Vue.prototype.$toast = _Toast;
Vue.prototype.$dialog = _Dialog;