export default [{
  path: '/task/list',
  name: 'taskList',
  meta: {
    title: '任务列表',
    keepAlive: false
  },
  component: () => import('@/views/task/list.vue')
}, {
  path: '/task/detail',
  name: 'taskDetail',
  meta: {
    title: '任务详情',
    keepAlive: false
  },
  component: () => import('@/views/task/detail.vue')
}];