//
//
//
//
//
//

export default {
  name: 'LOADING',
  data() {
    return {};
  }
};