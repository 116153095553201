import "core-js/modules/es.array.push.js";
import CryptoJs from 'crypto-js';
import globalConfig from '@/config';
import globalFunction from './globalFunction';
let UA, device;
if (typeof window !== 'undefined') {
  UA = window.navigator.userAgent.toLowerCase();
  if (UA.match(/iPad/i)) {
    device = 'iPad';
  }
  if (UA.match(/iPhone/i)) {
    device = 'iPhone';
  }
  if (UA.match(/android/i)) {
    device = 'android';
  }
}
/**
 * axios签名
 */
const KP = {
  key: 'AeE1Go2@5fd0d5ae',
  // 秘钥 16*n:
  iv: 'AeE1Go2@5fd0d5ae' // 偏移量
};

function getAesString(data, key, iv) {
  // 加密
  if (typeof word != 'string') {
    data = JSON.stringify(data);
  }
  key = CryptoJs.enc.Utf8.parse(key);
  iv = CryptoJs.enc.Utf8.parse(iv);
  let srcs = CryptoJs.enc.Utf8.parse(data);
  let encrypted = CryptoJs.AES.encrypt(srcs, key, {
    iv,
    mode: CryptoJs.mode.CBC,
    padding: CryptoJs.pad.Pkcs7
  });
  return encrypted; // 返回的是base64格式的密文
}

function getDAesString(encrypted, key, iv) {
  // 解密
  key = CryptoJs.enc.Utf8.parse(key);
  iv = CryptoJs.enc.Utf8.parse(iv);
  let decrypted = CryptoJs.AES.decrypt(encrypted, key, {
    iv,
    mode: CryptoJs.mode.CBC,
    padding: CryptoJs.pad.Pkcs7
  });
  return JSON.parse(CryptoJs.enc.Utf8.stringify(decrypted).toString()); //
}
// AES 对称秘钥加密
const aes = {
  en: data => getAesString(data, KP.key, KP.iv),
  de: data => getDAesString(data, KP.key, KP.iv)
};
// BASE64
const base64 = {
  en: data => CryptoJs.enc.Base64.stringify(data.ciphertext),
  de: data => CryptoJs.enc.Base64.stringify(CryptoJs.enc.Base64.parse(data))
};
// SHA256
const sha256 = data => {
  return CryptoJs.SHA256(data).toString();
};
// MD5
const md5 = data => {
  return CryptoJs.MD5(data).toString();
};

/**
 * 签名
 * @param token 身份令牌
 * @param timestamp 签名时间戳
 * @param data 签名数据
 */
const sign = (header, url) => {
  var signatureJson = {};
  if (globalConfig.isWeixin) {
    signatureJson = {
      device: device,
      nonce: globalFunction.randomStr(16),
      version: '2.0.0',
      secret: KP.key,
      timestamp: globalFunction.getFormatDate(Date.now(), 0, 'yyyymmddhhmmss'),
      token: header.token,
      clientId: header.clientId,
      requestUri: process.env.VUE_APP_BASE_RUL + url
    };
  } else {
    signatureJson = {
      device: native.getAppDevice(),
      nonce: globalFunction.randomStr(16),
      version: native.getAppVersion(),
      secret: KP.key,
      timestamp: globalFunction.getFormatDate(Date.now(), 0, 'yyyymmddhhmmss'),
      token: header.token,
      clientId: header.clientId,
      requestUri: process.env.VUE_APP_BASE_RUL + url
    };
  }
  let ret = [];
  for (let it in signatureJson) {
    let val = signatureJson[it];
    if (typeof val === 'object' && (
    //
    !(val instanceof Array) || val.length > 0 && typeof val[0] === 'object')) {
      val = JSON.stringify(val);
    }
    ret.push(it + '=' + val);
  }
  // 字典升序
  ret.sort();
  let signsrc = ret.join('&');
  header.sign = sha256(signsrc);
  header.device = signatureJson.device;
  header.nonce = signatureJson.nonce;
  header.version = signatureJson.version;
  header.secret = signatureJson.secret;
  header.timestamp = signatureJson.timestamp;
  header.requestUri = signatureJson.requestUr;
  return header;
};
export default {
  sign,
  /**
   * 前端数据加密
   */
  encrypt(word) {
    if (typeof word != 'string') {
      word = JSON.stringify(word);
    }
    let key = CryptoJs.enc.Utf8.parse(KP.key);
    let srcs = CryptoJs.enc.Utf8.parse(word);
    let encrypted = CryptoJs.AES.encrypt(srcs, key, {
      mode: CryptoJs.mode.ECB,
      padding: CryptoJs.pad.Pkcs7
    });
    return encrypted.toString();
  },
  /**
   * 前端数据解密
   */
  decrypt(word) {
    let key = CryptoJs.enc.Utf8.parse(KP.key);
    let decrypt = CryptoJs.AES.decrypt(word, key, {
      mode: CryptoJs.mode.ECB,
      padding: CryptoJs.pad.Pkcs7
    });
    return JSON.parse(CryptoJs.enc.Utf8.stringify(decrypt).toString());
  },
  aes,
  base64
};