import store from '@/store';
const {
  cachedRouteNames
} = store.state.keepAlive;
const changeRoutes = (route, type) => {
  const routeName = route.name;
  if (routeName && type === 'add' ? !cachedRouteNames.includes(routeName) : cachedRouteNames.includes(routeName)) {
    store.commit('keepAlive/UPDATECACHEDROUTENAMES', {
      action: type,
      route: routeName
    });
  }
};
// 定义添加缓存组件name函数，设置的时组件的name
const addRoutes = route => {
  changeRoutes(route, 'add');
};

// 定义删除缓存组件name函数，设置的是组件的name
const deleteRoutes = route => {
  changeRoutes(route, 'delete');
};

// 配置路由keepAlive状态
const setRouterBeforeEach = router => {
  router.beforeEach((to, from, next) => {
    // 对该组件是否读取缓存进行处理
    to.matched.forEach(item => {
      const routes = item.meta.cachedRouteNames;
      if (item.meta.keepAlive && (!routes || routes && (!from.name || routes.includes(from.name)))) {
        addRoutes(item);
      } else {
        deleteRoutes(item);
      }
    });
    next();
  });
  // 全局混入。在该组件被解析之后，若是属于需要缓存的组件，先将其添加到缓存配置中，进行缓存
  Vue.mixin({
    beforeRouteEnter(to, from, next) {
      next(() => {
        to.matched.forEach(item => {
          if (to.meta.keepAlive) {
            addRoutes(item);
          }
        });
      });
    }
  });
};
export default {
  setRouterBeforeEach
};