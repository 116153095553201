export default [{
  path: '/community/index',
  name: 'communityIndex',
  meta: {
    title: '七号街区',
    keepAlive: false
  },
  component: () => import('@/views/community/index.vue')
}, {
  path: '/community/hotTopic',
  name: 'communityHotTopic',
  meta: {
    title: '热门话题',
    keepAlive: false
  },
  component: () => import('@/views/community/hotTopic.vue')
}, {
  path: '/community/groupTotal',
  name: 'communityGroupTotal',
  meta: {
    title: '查看更多',
    keepAlive: false
  },
  component: () => import('@/views/community/groupTotal.vue')
}, {
  path: '/community/groupList',
  name: 'communityGroupList',
  meta: {
    title: '兴趣小组',
    keepAlive: false
  },
  component: () => import('@/views/community/groupList.vue')
}, {
  path: '/community/complaint',
  name: 'communityComplaint',
  meta: {
    title: '投诉',
    keepAlive: false
  },
  component: () => import('@/views/community/complaint.vue')
}, {
  path: '/community/detail',
  name: 'communityDetail',
  meta: {
    title: '话题详情',
    keepAlive: false
  },
  component: () => import('@/views/community/detail.vue')
}, {
  path: '/community/notice',
  name: 'communityNotice',
  meta: {
    title: '我的动态',
    keepAlive: false
  },
  component: () => import('@/views/community/notice.vue')
}, {
  path: '/community/create',
  name: 'communityCreate',
  meta: {
    title: '发布',
    keepAlive: false
  },
  component: () => import('@/views/community/create.vue')
}, {
  path: '/community/partition',
  name: 'communityPartition',
  meta: {
    title: '选择分区',
    keepAlive: false
  },
  component: () => import('@/views/community/partition.vue')
}, {
  path: '/community/search',
  name: 'communitySearch',
  meta: {
    title: '搜索',
    keepAlive: false
  },
  component: () => import('@/views/community/search.vue')
}, {
  path: '/community/searchResult',
  name: 'communitySearchResult',
  meta: {
    title: '搜索结果',
    keepAlive: false
  },
  component: () => import('@/views/community/searchResult.vue')
}, {
  path: '/home/tempIndex',
  name: 'tempIndex',
  meta: {
    title: '活动首页',
    keepAlive: false
  },
  component: () => import('@/views/home/tempIndex.vue')
}];