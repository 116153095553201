export default [{
  path: '/calculator/index',
  name: 'calculatorIndex',
  meta: {
    title: '财富计算器',
    keepAlive: false
  },
  component: () => import('@/views/calculator/index.vue')
}, {
  path: '/calculator/result',
  name: 'calculatorResult',
  meta: {
    title: '财富计算器',
    keepAlive: false
  },
  component: () => import('@/views/calculator/result.vue')
}, {
  path: '/calculator/list',
  name: 'calculatorList',
  meta: {
    title: '财富目标计算器列表',
    keepAlive: false
  },
  component: () => import('@/views/calculator/list.vue')
}, {
  path: '/calculator/entry',
  name: 'calculatorEntry',
  meta: {
    title: '财富计算',
    keepAlive: true
  },
  component: () => import('@/views/calculator/entry.vue')
}, {
  path: '/calculator/cash',
  name: 'calculatorCash',
  meta: {
    title: '财富计算报告',
    keepAlive: false
  },
  component: () => import('@/views/calculator/cash.vue')
}, {
  path: '/calculator/future',
  name: 'calculatorFuture',
  meta: {
    title: '财富计算报告',
    keepAlive: false
  },
  component: () => import('@/views/calculator/future.vue')
}, {
  path: '/calculator/selectPlan',
  name: 'calculatorSelectPlan',
  meta: {
    title: '选择产品',
    keepAlive: false
  },
  component: () => import('@/views/calculator/selectPlan.vue')
}, {
  path: '/calculator/share',
  name: 'calculatorShare',
  meta: {
    title: '分享设置',
    keepAlive: false
  },
  component: () => import('@/views/calculator/share.vue')
}, {
  path: '/calculator/history',
  name: 'calculatorHistory',
  meta: {
    title: '历史记录',
    keepAlive: false
  },
  component: () => import('@/views/calculator/history.vue')
}, {
  path: '/calculator/reportDetail',
  name: 'calculatorReportDetail',
  meta: {
    title: '报告记录',
    keepAlive: false
  },
  component: () => import('@/views/calculator/reportDetail.vue')
}, {
  path: '/calculator/reportDetailTurn',
  name: 'calculatorReportDetailTurn',
  meta: {
    title: '报告记录',
    keepAlive: false
  },
  component: () => import('@/views/calculator/reportDetailTurn.vue')
}, {
  path: '/calculator/friendReportDetail',
  name: 'calculatorFriendReportDetail',
  meta: {
    title: '好友报告记录',
    keepAlive: false
  },
  component: () => import('@/views/calculator/friendReportDetail.vue')
}];