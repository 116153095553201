export default [{
  path: '/activity/index',
  name: 'activityIndex',
  meta: {
    title: '活动主页',
    keepAlive: false
  },
  component: () => import('@/views/activity/index.vue')
}, {
  path: '/activity/list',
  name: 'activityList',
  meta: {
    title: '活动列表',
    keepAlive: false
  },
  component: () => import('@/views/activity/list.vue')
}, {
  path: '/activity/board',
  name: 'activityBoard',
  meta: {
    title: '活动看板',
    keepAlive: false
  },
  component: () => import('@/views/activity/board.vue')
}, {
  path: '/activity/highlight',
  name: 'activityHighlight',
  meta: {
    title: '高光时刻',
    keepAlive: false
  },
  component: () => import('@/views/activity/highlight.vue')
}, {
  path: '/activity/rank/single',
  name: 'activityRankSingle',
  meta: {
    title: '排行榜',
    keepAlive: false
  },
  component: () => import('@/views/activity/rank/single.vue')
}, {
  path: '/activity/rank/total',
  name: 'activityRankTotal',
  meta: {
    title: '排行榜',
    keepAlive: false
  },
  component: () => import('@/views/activity/rank/total.vue')
}, {
  path: '/activity/rank/opus',
  name: 'activityRankOpus',
  meta: {
    title: '排行榜',
    keepAlive: false
  },
  component: () => import('@/views/activity/rank/opus.vue')
}, {
  path: '/activity/card',
  name: 'activityCard',
  meta: {
    title: '活动名片',
    keepAlive: false
  },
  component: () => import('@/views/activity/card.vue')
}, {
  path: '/activity/overviewIndex',
  name: 'activityOverview',
  component: () => import('@/views/activity/overviewIndex'),
  meta: {
    title: '活动总览图',
    keepAlive: true
  }
}, {
  path: '/activity/common/share',
  name: 'activityCommonShare',
  component: () => import('@/views/activity/commonShare'),
  meta: {
    title: '分享',
    keepAlive: false
  }
}, {
  path: '/activity/highlightShare',
  name: 'activityHighlightShare',
  component: () => import('@/views/activity/highlightShare'),
  meta: {
    title: '高光时刻分享',
    keepAlive: false
  }
}, {
  path: '/activity/cardShare',
  name: 'activityCardShare',
  component: () => import('@/views/activity/cardShare'),
  meta: {
    title: '分享活动名片',
    keepAlive: false
  }
}];