var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "van-popup",
        {
          staticClass: "LoginDialog-wrap",
          attrs: { round: "", closeable: "", "close-on-click-overlay": false },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c("div", { staticClass: "login-box" }, [
            _c(
              "div",
              { staticClass: "header", style: "background:" + _vm.color + ";" },
              [_vm._v("注册")]
            ),
            _c(
              "div",
              { staticClass: "from-box" },
              [
                _c(
                  "van-form",
                  {
                    attrs: { "label-width": "1.4rem" },
                    on: { submit: _vm.onSubmit }
                  },
                  [
                    _c("van-field", {
                      staticClass: "fieldItem",
                      attrs: {
                        name: "手机号码",
                        label: "手机号码",
                        placeholder: "手机号码",
                        rules: [
                          {
                            validator: _vm.validator,
                            message: "请填写正确的手机号码"
                          }
                        ]
                      },
                      model: {
                        value: _vm.submitParams.mobile,
                        callback: function($$v) {
                          _vm.$set(_vm.submitParams, "mobile", $$v)
                        },
                        expression: "submitParams.mobile"
                      }
                    }),
                    _c("van-field", {
                      staticClass: "formItem",
                      attrs: {
                        clearable: "",
                        type: "digit",
                        label: "验证码",
                        placeholder: "请输入验证码"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "button",
                          fn: function() {
                            return [
                              _vm.submitParams.mobile.length >= 11 &&
                              _vm.isInSecond == true
                                ? _c(
                                    "van-button",
                                    {
                                      ref: "smsCode",
                                      attrs: {
                                        slot: "button",
                                        "native-type": "button",
                                        size: "mini"
                                      },
                                      slot: "button"
                                    },
                                    [
                                      _c("van-count-down", {
                                        staticStyle: { color: "#777" },
                                        attrs: {
                                          time: _vm.time,
                                          color: "#4454E6",
                                          format: " ss 秒后重试"
                                        },
                                        on: { finish: _vm.countDownFinish }
                                      })
                                    ],
                                    1
                                  )
                                : _vm.submitParams.mobile.length >= 11
                                ? _c(
                                    "van-button",
                                    {
                                      attrs: {
                                        "native-type": "button",
                                        size: "small",
                                        type: "primary",
                                        color: _vm.color
                                      },
                                      on: { click: _vm.getSmsCode }
                                    },
                                    [_vm._v("发送验证码")]
                                  )
                                : _c(
                                    "van-button",
                                    {
                                      attrs: {
                                        "native-type": "button",
                                        disabled: "",
                                        size: "small",
                                        type: "primary",
                                        color: _vm.color
                                      }
                                    },
                                    [_vm._v("发送验证码")]
                                  )
                            ]
                          },
                          proxy: true
                        }
                      ]),
                      model: {
                        value: _vm.submitParams.smsCode,
                        callback: function($$v) {
                          _vm.$set(_vm.submitParams, "smsCode", $$v)
                        },
                        expression: "submitParams.smsCode"
                      }
                    }),
                    _c("van-field", {
                      staticClass: "fieldItem",
                      attrs: {
                        name: "邀请码",
                        label: "邀请码",
                        placeholder: "邀请码",
                        rules: [{}]
                      },
                      model: {
                        value: _vm.submitParams.agentCode,
                        callback: function($$v) {
                          _vm.$set(_vm.submitParams, "agentCode", $$v)
                        },
                        expression: "submitParams.agentCode"
                      }
                    }),
                    _c(
                      "div",
                      { staticClass: "checkbox-wrap" },
                      [
                        _c(
                          "van-checkbox",
                          {
                            attrs: { "icon-size": ".26rem" },
                            model: {
                              value: _vm.checked,
                              callback: function($$v) {
                                _vm.checked = $$v
                              },
                              expression: "checked"
                            }
                          },
                          [
                            _c("p", [
                              _vm._v("我已阅读并同意 "),
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.userProtocol = true
                                    }
                                  }
                                },
                                [_vm._v("《用户协议》")]
                              ),
                              _vm._v("、"),
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.dataProtocol = true
                                    }
                                  }
                                },
                                [_vm._v("《隐私政策》")]
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "submit-btn" },
                      [
                        _c(
                          "van-button",
                          {
                            attrs: {
                              round: "",
                              type: "info",
                              size: "small",
                              "native-type": "submit",
                              color: _vm.color
                            }
                          },
                          [_vm._v("立即注册")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "van-popup",
        {
          model: {
            value: _vm.userProtocol,
            callback: function($$v) {
              _vm.userProtocol = $$v
            },
            expression: "userProtocol"
          }
        },
        [
          _c(
            "div",
            { staticClass: "protocol" },
            [
              _c("van-nav-bar", {
                attrs: {
                  title: "银保赢会员服务协议",
                  "left-text": "返回",
                  "left-arrow": ""
                },
                on: {
                  "click-left": function($event) {
                    _vm.userProtocol = false
                  }
                }
              }),
              _c("span", [_vm._v("欢迎您使用银保赢服务！")]),
              _c("span", [
                _vm._v(
                  "下述“银保赢”的表述，既包括指代银保赢服务，也包括指代银保赢服务的提供者。“用户”或“会员”是指使用银保赢相关服务的使用人，在本协议中更多地称为“您”。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "在使用银保赢服务之前，请您务必审慎阅读、充分理解《银保赢会员服务协议》（以下简称“本协议”），特别是限制或免除责任的相应条款、法律适用和争议解决条款。限制或免除责任的相应条款以加粗形式提示您注意。未成年人应在法定监护人的陪同下阅读。您点击“阅读并同意注册协议”按钮即表示您确认对本协议全部条款含义已充分理解并完全接受。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "您同意，银保赢有权在必要时修改本协议条款并按法律规定公示，您可以在相关服务页面查阅最新版本的协议条款。"
                )
              ]),
              _c("span", { staticClass: "smallTitle" }, [
                _vm._v("第一条 协议的范围")
              ]),
              _c("span", [
                _vm._v(
                  "本协议是您与银保赢服务的提供者北京天融互联科技有限公司（以下简称“天融互联”）之间关于您注册、登录、使用银保赢服务（包括但不限于PC端、移动端设备等方式登录并使用银保赢服务）所订立的协议。"
                )
              ]),
              _c("span", { staticClass: "smallTitle" }, [
                _vm._v("第二条 账户使用规则")
              ]),
              _c("span", [_vm._v("一、会员注册")]),
              _c("span", [
                _vm._v(
                  "1、您在注册或使用银保赢服务时可能需要提供一些必要的信息。您须保证所填写的注册表和所提供的资料真实、准确、完整，否则无法使用本服务或在使用过程中受到限制。 对于因您提供的信息不真实、不准确或不完整导致的损失由您承担。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "2、银保赢有权根据您所提供注册资料的真实性、准确性、完整性以及是否符合银保赢规定的其他条件，决定您的注册成功与否。如您提供的注册资料存在虚假信息、或冒用他人信息的情况，天融互联作为本协议服务的提供方有权注销该账号。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "3、用户在使用银保赢过程中，所提供的注册信息发生变动，应及时进行变更。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "4、您自愿注册并使用银保赢，确认以银保赢的技术操作方式管理金融或非金融产品账户。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "5、未成年人在注册使用银保赢的服务前，应事先取得监护人的同意。监护人的同意包括但不限于口头的、书面的或者其他形式。监护人应承担未成年人在网站隐私权保护的首要责任，且未成年人及其监护人应承担使用本服务而导致的一切后果。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "6、 银保赢会员一经注册，即可在天融互联相关公司、合作伙伴等平台中通行使用。在用银保赢账号登录并使用其他平台提供的产品或服务时，除遵守本协议约定外，还应遵守该等平台服务协议的约定、平台公布的规则以及有关正确使用平台服务的说明和操作指引。银保赢和其他平台对可能出现的纠纷在法律规定和约定的范围内各自承担责任。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "7、 银保赢为会员提供综合金融及生活服务，为客户体验及功能服务考虑，您在不同平台入口注册时被要求填写的内容可能不完全一致，因此，在使用更高级别服务时，为了给您提供更好的服务及为了您的账户安全，可能需要您补充完整信息后方可使用。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "8、资费说明，会产生数据流量，用户需自行向运营商了解相关资费；"
                )
              ]),
              _c("span", [
                _vm._v(
                  "9、特定服务或产品，有单独协议、业务规则，需使用前阅读并同意；"
                )
              ]),
              _c("span"),
              _c("span", [_vm._v("二、用户名和密码安全")]),
              _c("span", [
                _vm._v(
                  "您须妥善保管本人的用户名和密码，及装载和使用银保赢服务的设备。凡使用该用户名和密码的行为，银保赢视为您本人的操作，操作所产生的电子信息记录均为银保赢会员行为的有效凭据。您对利用该用户名和密码所进行的一切活动负全部责任。"
                )
              ]),
              _c("span", [
                _vm._v("您应按照机密的原则设置和保管密码，包括但不限于：")
              ]),
              _c("span", [
                _vm._v(
                  "1、避免使用生日、电话号码等与本人明显相关的数字作为密码；"
                )
              ]),
              _c("span", [
                _vm._v("2、收到初始密码后应通过登录银保赢尽快重新设置密码；")
              ]),
              _c("span", [_vm._v("3、应定期更换密码并牢记；")]),
              _c("span", [
                _vm._v("4、在设备上输入密码时，应防止旁人或不安全设备窥视；")
              ]),
              _c("span", [
                _vm._v(
                  "5、不应在网吧等公众场所的或他人的电脑或网络设备上使用银保赢；"
                )
              ]),
              _c("span", [
                _vm._v("6、结束使用银保赢，应选择安全退出方式终止当前服务；")
              ]),
              _c("span", [
                _vm._v("7、发现密码泄漏、遗忘，应及时办理密码重置手续。")
              ]),
              _c("span", [_vm._v("三、暂停和注销账户")]),
              _c("span", [
                _vm._v("长期不登录或不使用银保赢账户，应暂停或注销银保赢账户。")
              ]),
              _c("span", [
                _vm._v(
                  "您可直接登录银保赢账户功能区进行暂停或注销银保赢账户及变更注册信息等操作。您暂停银保赢账户后，在暂停期间将不能使用银保赢账户，待暂停期结束后，方可重新使用；银保赢会员注销银保赢账户后，其会员资格将被注销，须重新申请注册银保赢方可使用。"
                )
              ]),
              _c("span", { staticClass: "smallTitle" }, [
                _vm._v("第三条 会员的禁止行为")
              ]),
              _c("span", [
                _vm._v(
                  "一、您使用银保赢服务时，禁止从事以下行为，银保赢的系统记录可作为实施该行为的证据："
                )
              ]),
              _c("span", [
                _vm._v(
                  "1、发布、传送、传播、储存危害国家安全统一、破坏社会稳定、违反公序良俗、侮辱、诽谤、淫秽、暴力以及任何违反国家法律法规的内容；"
                )
              ]),
              _c("span", [
                _vm._v(
                  "2、发布、传送、传播、储存侵害他人知识产权、商业秘密等合法权利的内容；"
                )
              ]),
              _c("span", [
                _vm._v("3、恶意虚构事实、隐瞒真相以误导、欺骗他人；")
              ]),
              _c("span", [_vm._v("4、发布、传送、传播广告信息及垃圾信息；")]),
              _c("span", [
                _vm._v(
                  "5、将有关干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案和程序等资料，加以上载、张贴、发送电子邮件或以其他方式传送；"
                )
              ]),
              _c("span", [
                _vm._v(
                  "6、恶意登录、注册，恶意使用系统功能及服务；干扰或破坏银保赢服务或与银保赢服务相连的服务器和网络，或不遵守银保赢服务网络使用的规定；"
                )
              ]),
              _c("span", [
                _vm._v(
                  "7、恶意诋毁或损害银保赢声誉、利用银保赢会员身份从事违反国家法律法规活动等行为；"
                )
              ]),
              _c("span", [
                _vm._v(
                  "8、违反任何相关的中国法律、行政法规、规章、条例等其他具有法律效力的规范。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "二、如果银保赢发现或收到他人举报您发布的信息违反本条约定，银保赢有权进行独立判断并采取技术手段予以删除、屏蔽或断开链接。同时，银保赢有权视会员的行为性质，采取包括但不限于暂停或终止服务，限制、冻结或终止账号使用等措施并追究法律责任。"
                )
              ]),
              _c("span", { staticClass: "smallTitle" }, [
                _vm._v("第四条 服务的变更、中断或终止")
              ]),
              _c("span", [
                _vm._v(
                  "一、您理解，银保赢需要定期或不定期地对提供服务的系统或相关的设备进行检修或者维护，或对银保赢系统进行升级、改造。如因此类情况而造成服务在合理时间内的中断，银保赢无需为此承担任何责任。但银保赢应尽可能事先进行通告并根据情况在相应服务页面提前作出通知。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "二、 如发生下列任何一种情形，银保赢有权不经通知而中断或终止向您提供本协议项下的服务而无需对您或任何第三方承担任何责任："
                )
              ]),
              _c("span", [
                _vm._v(
                  "1、根据法律规定您应提交真实信息，而您提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；"
                )
              ]),
              _c("span", [_vm._v("2、您违反相关法律法规或本协议的约定；")]),
              _c("span", [_vm._v("3、按照法律规定或主管部门的要求；")]),
              _c("span", [_vm._v("4、出于安全的原因或其他必要的情形。")]),
              _c("span", [
                _vm._v(
                  "三、您有责任自行备份存储在本服务中的数据。如果您的服务被终止，银保赢可以从服务器上永久地删除您的数据，但法律法规另有规定的除外。服务终止后银保赢没有义务向您返还数据。"
                )
              ]),
              _c("span", { staticClass: "smallTitle" }, [
                _vm._v("第五条 授权条款")
              ]),
              _c("span", [
                _vm._v(
                  "您同意：本人授权天融互联，除法律另有规定之外，将本人提供给天融互联的信息、享受天融互联服务产生的信息（包括本条款签署之前提供和产生的信息）以及天融互联根据本条款约定查询、收集的信息，用于天融互联及其因服务必要委托的合作伙伴为本人提供服务、推荐产品、开展市场调查与信息数据分析。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "本人授权天融互联，除法律另有规定之外，基于为本人提供更优质服务和产品的目的，向天融互联因服务必要开展合作的伙伴提供、查询、收集本人的信息。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "前述“信息”可能包括但不限于本人的身份信息、财产信息、账户信息、信用信息、交易信息、医疗健康信息、行踪轨迹信息、电子设备信息、电子设备操作日志及其他与本人相关的信息。为确保本人信息的安全，天融互联对上述信息负有保密义务，并尽最大努力采取各种措施保证信息安全。本条款自本协议签署时生效，具有独立法律效力，不受协议成立与否及效力状态变化的影响。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "本条所称“天融互联”是指北京天融互联科技有限公司及其直接或间接控股的公司，以及北京天融互联科技有限公司直接或间接作为其单一最大股东的公司。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "如您不同意上述授权条款的部分或全部，可〔致电客服热线（400-666-1566）〕取消或变更授权。"
                )
              ]),
              _c("span", { staticClass: "smallTitle" }, [
                _vm._v("第六条 免责条款")
              ]),
              _c("span", [
                _vm._v(
                  "您理解并同意，银保赢在以下情况下不承担责任（包括但不限于）："
                )
              ]),
              _c("span", [
                _vm._v(
                  "一、银保赢不对本服务提供任何形式的保证，包括但不限于本服务符合您的需求，本服务不受干扰、及时提供或免于出错。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "二、银保赢的合作单位所提供的服务内容及品质由该合作单位自行负责。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "三、银保赢不保证为向会员提供便利而设置的外部链接的准确性和完整性，同时对于该等外部链接指向的不由银保赢实际控制的任何网页上的内容，银保赢不承担任何责任。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "四、对于本协议第四条造成的服务变更、中断或终止，银保赢不承担责任。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "五、因以下情况没有正确执行您提交的银保赢会员操作指令而造成损失的，银保赢不承担任何责任："
                )
              ]),
              _c("span", [_vm._v("1、指令信息不明、存在乱码或不完整等；")]),
              _c("span", [
                _vm._v("2、您所拥有的产品或服务发生失效、终止等情况；")
              ]),
              _c("span", [_vm._v("3、其他银保赢无过失的情况。")]),
              _c("span", [
                _vm._v(
                  "六、对于受到计算机病毒、木马或其他恶意程序、黑客攻击所造成的损失。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "七、您须安全使用账户，妥善保存银保赢的用户名、密码，对您保管不善造成的损失银保赢不承担责任。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "八、如果您在共享环境下或在电脑被远程监控的情况下登录银保赢，由此造成的损失银保赢不承担责任。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "九、银保赢不允许您使用他人身份信息注册，否则，因此产生的法律责任将由您本人承担，给他人造成损失的，您应给予赔偿。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "十、如您使用他人账户，须事先获得他人同意。一旦输入正确的用户名和密码，银保赢即视您为已获得他人同意。您与他人之间因此产生的任何纠纷与银保赢无关，对您或他人的损失银保赢不承担责任，并且如造成银保赢损失，银保赢将保留追索的权利。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "十一、如果银保赢会员将账户详细信息输入其他任何服务系统而不是输入银保赢相关网页安全登录系统，由此造成的损失银保赢不承担责任。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "十二、银保赢会员通过移动通讯设备登录相关网站和使用相关服务，因通讯过程中导致信息的不完整、丢失或被第三方拦截等不可归责于银保赢的情形，银保赢不承担责任。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "十三、因无法预见、无法避免且无法克服的不可抗力事件，导致银保赢在本协议项下的义务无法履行或无法按时履行，银保赢在法律允许的范围内免责。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "十四、如果银保赢存在投入使用时的科学技术水平尚不能发现的缺陷，由此造成的损失银保赢不承担责任。"
                )
              ]),
              _c("span", { staticClass: "smallTitle" }, [
                _vm._v("第七条 知识产权声明")
              ]),
              _c("span", [
                _vm._v(
                  "一、银保赢在本服务中所包含的内容（包括但不限于网页、文字、图片、音频、视频、图表等）及提供本服务时所依托软件的著作权、专利权及其他知识产权均归银保赢及关联公司所有。银保赢在本服务中所使用的“银保赢”等商业标识，其商标权和相关权利归天融互联所有。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "二、本服务包含的内容的知识产权均受到法律保护，您若侵犯银保赢相关知识产权将承担损害赔偿责任。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "三、非经天融互联书面同意，任何人不得擅自使用、修改、复制、公开传播、改变、散布、发行或公开发表或者进行还原工程解编或反向组绎本软件程序或内容。天融互联保留追究上述末经许可行为的权利。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "四、尊重知识产权是您应尽的义务，如有违反，您应承担损害赔偿责任(包括但不限于诉讼费用及律师费用等）。"
                )
              ]),
              _c("span", { staticClass: "smallTitle" }, [
                _vm._v("第八条 隐私权保护规则")
              ]),
              _c("span", [
                _vm._v(
                  "银保赢承诺采取严格的隐私政策，尊重并保护会员隐私。银保赢将按照本隐私权保护规则（以下简称“本规则”）收集、使用及共享您的个人信息。本规则包含了我们收集、存储、保护、使用和共享您的个人信息的条款，建议您完整地阅读本规则，以帮助您了解维护自己隐私权的方式。"
                )
              ]),
              _c("span", [_vm._v("一、个人信息的收集范围")]),
              _c("span", [
                _vm._v(
                  "当您使用银保赢服务时，您会向我们主动提供一些信息，您同意并许可我们收集您的一些信息，以向您提供服务。"
                )
              ]),
              _c("span", [_vm._v("我们收集信息的范围主要包括：")]),
              _c("span", [
                _vm._v(
                  "1、为了遵守法律法规的要求，以及基于提供服务及改进服务质量的合理需要，我们需要识别您的身份。在您注册并使用银保赢时，您可能要向我们提供一些个人身份资料，例如姓名、证件类型和证件号码、手机号码、联系地址、电子邮件地址等。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "2、除上述信息外，我们为向您提供更个性化、更便捷的服务的需要而收集您的其他信息，包括但不限于年龄、生日、职业、教育程度等，以及其他资料如使用的语言种类、喜好等。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "3、当您使用本协议提供的银保赢服务时，我们可能会记录您操作的相关信息以及与银保赢服务相关的日志信息，这些信息可帮助我们向您提供精准服务并更好地保护您的账户安全。"
                )
              ]),
              _c("span", [_vm._v("二、收集个人信息的目的")]),
              _c("span", [
                _vm._v(
                  "您理解并同意，银保赢可能将会员所提供的个人信息用于以下一项或多项目的："
                )
              ]),
              _c("span", [_vm._v("1、更准确的身份确认；")]),
              _c("span", [_vm._v("2、处理会员购买商品及服务的要求；")]),
              _c("span", [
                _vm._v("3、满足会员使用各类网上服务及其他服务的要求；")
              ]),
              _c("span", [
                _vm._v(
                  "4、更好的会员服务和会员沟通，包括项目研究及计划发展、向会员发送相关新闻和服务信息、为会员提供有关优惠及推荐产品；"
                )
              ]),
              _c("span", [_vm._v("5、预防或禁止非法的活动；")]),
              _c("span", [_vm._v("6、经您许可的其他用途。")]),
              _c("span", [_vm._v("三、保护和存储个人信息")]),
              _c("span", [
                _vm._v(
                  "为确保个人资料的正确使用，银保赢采取了适当的管理措施以保障网站收集个人资料的保密性。银保赢采用了安全技术措施来保护数据，建立严格的信息存储和使用制度规范，并对可能接触到您的信息的人员也采取了严格管理。"
                )
              ]),
              _c("span", [_vm._v("四、个人信息的共享及披露")]),
              _c("span", [
                _vm._v(
                  "我们对您的信息承担保密义务，未经您允许的情况下不公开您的个人信息。您同意，我们在下列情况下将您的信息与第三方共享："
                )
              ]),
              _c("span", [_vm._v("1、获得您的同意或授权；")]),
              _c("span", [
                _vm._v(
                  "2、为提供您要求的服务所必需，某些服务需由合作伙伴（包括但不限于提供相关技术支持的公司、快递公司、提供奖品的公司）提供或共同提供，为了向您提供此类服务，银保赢有必要与提供该服务的合作伙伴共享完成该服务所需要的会员个人信息；"
                )
              ]),
              _c("span", [
                _vm._v(
                  "3、银保赢与第三方共同进行推广活动，在推广活动中所收集的全部或部分个人信息可能会与该第三方分享；"
                )
              ]),
              _c("span", [
                _vm._v(
                  "4、根据法律法规或政府的强制性规定，必须向有关司法或政府部门提供会员个人信息；"
                )
              ]),
              _c("span", [_vm._v("5、为完成合并、分立、收购或资产转让；")]),
              _c("span", [
                _vm._v(
                  "6、为防止会员本人、他人的合法权益或社会公共利益受到重大危害。"
                )
              ]),
              _c("span", [_vm._v("五、对第三方责任的声明")]),
              _c("span", [
                _vm._v(
                  "银保赢因服务必要，可能会将相关网站链接至天融互联开展合作的伙伴的网站（统称“第三方网站”）。本《隐私权保护规则》并不适用于这些第三方网站，因为它们不受银保赢控制。如果您使用银保赢提供的链接访问第三方网站，这些网站的运营商可能会收集您的个人信息。银保赢尽力确保所有链接的第三方网站采用同等的个人信息保护措施，但是银保赢不对这些第三方网站上的活动、隐私权政策或隐私保护水平承担任何法律或其他责任。"
                )
              ]),
              _c("span", { staticClass: "smallTitle" }, [
                _vm._v("第九条 法律效力")
              ]),
              _c("span", [
                _vm._v(
                  "一、会员使用银保赢服务即视为会员已阅读本协议并接受本协议的约束。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "二、本协议所有条款的标题仅为阅读方便，本身并无实际含义，不能作为本协议含义解释的依据。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "三、本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。"
                )
              ]),
              _c("span", { staticClass: "smallTitle" }, [
                _vm._v("第十条 法律适用和争议解决")
              ]),
              _c("span", [
                _vm._v(
                  "一、本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不含冲突法），如无相关法律规定的，则应参照通用国际商业惯例和/或行业惯例。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "二、您与银保赢在履行本协议的过程中，如发生争议，应协商解决。协商不成的，双方均有权向有管辖权的人民法院提起民事诉讼。仲裁裁决是终局的，对双方均有约束力。"
                )
              ]),
              _c(
                "div",
                { staticClass: "footer" },
                [
                  _c(
                    "van-button",
                    {
                      staticClass: "footerBtn",
                      attrs: { type: "primary", block: "" },
                      on: {
                        click: function($event) {
                          _vm.userProtocol = false
                        }
                      }
                    },
                    [_vm._v("关 闭")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "van-popup",
        {
          model: {
            value: _vm.dataProtocol,
            callback: function($$v) {
              _vm.dataProtocol = $$v
            },
            expression: "dataProtocol"
          }
        },
        [
          _c(
            "div",
            { staticClass: "protocol" },
            [
              _c("van-nav-bar", {
                attrs: {
                  title: "信息共享授权条款",
                  "left-text": "返回",
                  "left-arrow": ""
                },
                on: {
                  "click-left": function($event) {
                    _vm.dataProtocol = false
                  }
                }
              }),
              _c("span", [
                _vm._v(
                  "为了更好地为您提供产品与服务，履行我们的社会责任与法定义务，同时实现我们的商业价值，我们将与关联公司共享您的个人信息。所涉行业包括：保险、银行、资产管理、财富管理、融资租赁、普惠金融、健康医疗等。“关联公司〞是指北京天融互联科技有限公司及其通过股权、协议或其他方式直接或间接控制、共同控制或施加重大影响的公司；存在个人信息共享的关联公司范围以所附列表为准。共享个人信息的类型包括：姓名、性别、出生年月、身份证号、手机号等。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "基于数据共享，我们可能会与关联公司联合对您的个人信息进行特征提取和用户画像，分析或预测您在个人喜好、行为方式等方面的特征，开展数据挖掘工作。我们将个人信息保护与数据安全作为数据共享的前提条件，辅以无差别、一体化的管理措施与技术措施作为保障。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "基本业务功能系为向您提供产品与服务所必需，或是为防范风险、保障安全、实现合规所必要。请您授权我们进行该等共享，否则我们难以为您提供安全的、统一的服务。具体而言，我们的基本业务功能包括"
                )
              ]),
              _c("span", [
                _vm._v(
                  "1. 为您提供产品与服务：我们可能共享您必要的个人信息，以便联合向您提供您所要求的产品或服务。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "2. 统一客户服务与消费者保护：我们可能共享您必要的个人信息，从而为您提供用户告知、信息查询、用户投诉、电话热线等客服功能，妥善处理用户反馈、保障消费者权益。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "3. 风险防控与安全保障：我们可能共享您必要的个人信息，实现我们在风险防控、防范欺诈、合规内控等方面的要求，从而在安全的前提下为您提供产品与服务。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "4. 法定义务：我们可能共享您必要的个人信息，履行我们在反洗钱、反恐怖融资、投资者适当性管理、监管报送等方面的法定义务。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "在共享您的个人信息之前以及共享的过程中，我们将充分评估该等共享的合法性、正当性、必要性，并采用适当的管理措施与技术措施，以保障您的个人信息安全。如果我们的关联公司想要改变本隐私政策声明的个人信息使用目的、处理方式等，将再次征求您的授权同意。"
                )
              ]),
              _c("span", [
                _vm._v(
                  "除了取得您的同意的情形，我们还会在法律法规允许的范围内共享您的个人信息，包括："
                )
              ]),
              _c("span", [
                _vm._v("1. 为订立或者履行个人作为一方当事人的合同所必需；")
              ]),
              _c("span", [_vm._v("2. 为履行法定职责或者法定义务所必需；")]),
              _c("span", [
                _vm._v(
                  "3. 为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；"
                )
              ]),
              _c("span", [
                _vm._v("4. 依法在合理的范围内处理已公开的个人信息；")
              ]),
              _c("span", [
                _vm._v(
                  "5. 为公共利益实施新闻报道、與论监督等行为，在合理的范围内处理个人信息；"
                )
              ]),
              _c("span", [_vm._v("6. 法律、行政法规规定的其他情形。")]),
              _c(
                "div",
                { staticClass: "footer" },
                [
                  _c(
                    "van-button",
                    {
                      staticClass: "footerBtn",
                      attrs: { type: "primary", block: "" },
                      on: {
                        click: function($event) {
                          _vm.dataProtocol = false
                        }
                      }
                    },
                    [_vm._v("关 闭")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }