let UA, isIos, isWeixin;
if (typeof window !== 'undefined') {
  UA = window.navigator.userAgent.toLowerCase();
  isIos = false;
  isWeixin = UA.indexOf('micromessenger') != -1;
  if (UA.match(/ipad/) || UA.match(/iphone/) || UA.match(/ipod/)) {
    isIos = true;
  }
}

/**
 * 判断是否为原生  true:原生 false:非原生
 * @returns {boolean}
 */
function isNative() {
  return typeof native !== 'undefined';
}

/**
 * 获取UserId
 * isNative() true:原生 false:非微信 非原生  可能为手机自带浏览器
 */
function getUserId() {
  return isNative() ? native.jsGetUserId() : '';
}
function getToken() {
  return isNative() ? native.jsGetToken() : '';
}
function getClientId() {
  return isNative() ? isIos ? '11000' : '10000' : '';
}

/*globalConfig对象中添加或删除字段需要修改 axios中apiObj对象 和  productFunction中updateApiObj方法*/
let globalConfig;
if (process.env.NODE_ENV == 'product') {
  // 线上生产环境配置
  globalConfig = {
    apiPath: process.env.VUE_APP_BASE_RUL,
    // wx调用接口测试地址
    sharePath: process.env.VUE_APP_BASE_RUL,
    //分享H5页面地址
    userOpenId: isWeixin ? localStorage.getItem('userOpenId') : getUserId(),
    // 用户ID
    token: isWeixin ? localStorage.getItem('userToken') : getToken(),
    // 用户token
    clientId: isWeixin ? 'wxd6eb3e0584a7935c' : isIos ? '11000' : '10000',
    // 系统中的clientId,公众号的ID 测试
    appIdWX: 'wxd6eb3e0584a7935c',
    //  对应微信测试
    isWeixin,
    isIos,
    qrImgUrl: 'http://cdn.e-tianrong.com/static/image/health/share/tryby.jpg'
  };
} else if (process.env.NODE_ENV == 'test') {
  // 线上测试环境配置64356
  globalConfig = {
    apiPath: process.env.VUE_APP_BASE_RUL,
    // wx调用接口测试地址
    sharePath: process.env.VUE_APP_BASE_RUL,
    //分享H5页面地址
    userOpenId: isWeixin ? localStorage.getItem('userOpenId') : getUserId(),
    // 用户ID
    token: isWeixin ? localStorage.getItem('userToken') : getToken(),
    // 用户token
    clientId: isWeixin ? 'wx1b5a73c69bdd4ba5' : isIos ? '11000' : '10000',
    // 系统中的clientId,公众号的ID 测试
    appIdWX: 'wx1b5a73c69bdd4ba5',
    //  对应微信测试
    isWeixin,
    isIos,
    qrImgUrl: 'http://cdn.e-tianrong.com/static/image/health/share/trjksh.jpg'
  };
} else if (process.env.NODE_ENV == 'uat') {
  // 线上测试环境配置
  globalConfig = {
    apiPath: process.env.VUE_APP_BASE_RUL,
    // wx调用接口测试地址
    sharePath: process.env.VUE_APP_BASE_RUL,
    //分享H5页面地址
    userOpenId: isWeixin ? localStorage.getItem('userOpenId') : getUserId(),
    // 用户ID
    token: isWeixin ? localStorage.getItem('userToken') : getToken(),
    // 用户token
    clientId: isWeixin ? 'wxd6eb3e0584a7935c' : isIos ? '11000' : '10000',
    // 系统中的clientId,公众号的ID 测试
    appIdWX: 'wxd6eb3e0584a7935c',
    //  对应微信测试
    isWeixin,
    isIos,
    qrImgUrl: 'http://cdn.e-tianrong.com/static/image/health/share/tryby.jpg'
  };
} else if (process.env.NODE_ENV == 'yby') {
  // 线上测试环境配置
  globalConfig = {
    apiPath: process.env.VUE_APP_BASE_RUL,
    // wx调用接口测试地址
    sharePath: process.env.VUE_APP_BASE_RUL,
    //分享H5页面地址
    userOpenId: isWeixin ? localStorage.getItem('userOpenId') : getUserId(),
    // 用户ID
    token: isWeixin ? localStorage.getItem('userToken') : getToken(),
    // 用户token
    clientId: isWeixin ? 'wx2f6e948a7511ec2a' : isIos ? '11000' : '10000',
    // 系统中的clientId,公众号的ID 测试
    appIdWX: 'wx2f6e948a7511ec2a',
    //  对应微信测试
    isWeixin,
    isIos,
    qrImgUrl: 'http://cdn.e-tianrong.com/static/image/health/share/rjyby.jpg'
  };
} else {
  // 本地测试写死配置
  globalConfig = {
    apiPath: process.env.VUE_APP_BASE_RUL,
    // wx调用接口测试地址
    sharePath: process.env.VUE_APP_BASE_RUL,
    //分享H5页面地址
    userOpenId: 'o3-lw6DpGaae5ruz5gOFD4SC1T5k"',
    // 用户ID
    token: '1d471a253fd8aafd2526b8494d53ecdf',
    // 对应微信测试
    clientId: 'wxd6eb3e0584a7935c',
    //  对应微信测试
    appIdWX: 'wxd6eb3e0584a7935c',
    //  对应微信测试
    isWeixin: true,
    isIos,
    qrImgUrl: 'http://cdn.e-tianrong.com/static/image/health/share/trjksh.jpg'
  };
}
module.exports = globalConfig;