import "vant/es/toast/style";
import _Toast from "vant/es/toast";
// 引入公共方法并绑定到Vue原型
import globalFunction from './globalFunction';
import productFunction from './productFunction';
Vue.prototype.globalFunction = globalFunction;
Vue.prototype.productFunction = productFunction;
import registerDialog from '@/components/registerDialog/registerDialog';
import LoginDialog from '@/components/LoginDialog/LoginDialog';
Vue.use(registerDialog);
Vue.prototype.$login = LoginDialog.install;
import axios from 'axios';
import globalConfig from '@/config';
import store from '@/store';
import cryptoJS from '@/utils/cryptoJS';
import { ACTION_CODE, SHARE_TITLE, SHARE_DESC } from "@/utils/share";
Vue.prototype.ACTION_CODE = ACTION_CODE;
Vue.prototype.SHARE_TITLE = SHARE_TITLE;
Vue.prototype.SHARE_DESC = SHARE_DESC;
let baseUrlC = globalConfig.apiPath;
// if(process.env.NODE_ENV=='uat'||process.env.NODE_ENV=='product'){
//     if(location.href.indexOf('yby.e-tianrong.com')!==-1){
//         baseUrlC = 'https://yby.e-tianrong.com'
//     }else if(location.href.indexOf('10.160.187.39')!==-1){
//         baseUrlC = 'http://10.160.187.39:8700'
//     }
// }

Vue.prototype.apiObj = {
  apiPath: globalConfig.apiPath,
  sharePath: globalConfig.apiPath,
  cdnPath: 'http://cdn.e-tianrong.com',
  userOpenId: globalConfig.userOpenId,
  token: globalConfig.token,
  appIdWX: globalConfig.appIdWX,
  isWeixin: globalConfig.isWeixin,
  isIos: globalConfig.isIos,
  clientId: globalConfig.clientId,
  promoteFeeInd: '',
  //推广费是否显示 Y显示 N不显示,使用时再赋值
  userNickname: '',
  //进入mine index中赋值，给咨询客服使用
  trBaoPath: 'https://tr.hizom.cn',
  //trbao环境下域名，但请求还是发送到apiPath路径下
  wxName: '',
  //微信姓名  不能直接使用，需调用productFuction中的getWxNameHead方法
  wxHeadUrl: '',
  //微信头像url 需调用productFuction中的getWxNameHead方法
  mapKey: 'M7GBZ-S4VLJ-TFSFE-KU3ZG-A3EUE-24FCD',
  //对应腾讯位置的秘钥
  qrImgUrl: globalConfig.qrImgUrl
};
let axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 1000 * 60
});
/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */

let allUse = true;
const errorHandle = (status, message) => {
  // 状态码判断
  switch (status) {
    // 401: 未登录状态，跳转登录页
    case 401:
      if (Vue.prototype.apiObj.isWeixin) {
        if (allUse) {
          allUse = false;
          localStorage.removeItem('userToken');
          globalFunction.goLogin();
        }
      } else {
        native.jsCallLogin();
      }
      break;
    // 403 token过期 清除token并跳转登录页
    case 403:
      _Toast.fail(message);
      break;
    // 404请求不存在
    case 404:
      _Toast.fail("请求的资源不存在");
      break;
    case 444:
      _Toast.fail(message);
      break;
    case 503:
      _Toast.fail(message);
      break;
    default:
      _Toast.fail("网络错误，请稍后重试");
      break;
  }
};
/**
 * 响应拦截器
 */
axiosInstance.interceptors.response.use(res => {
  // 请求成功
  return Promise.resolve(res);
},
// 请求失败
error => {
  const {
    response
  } = error;
  if (response) {
    // 请求已发出，但是不在2xx的范围
    errorHandle(response.status, response.data.message);
    return Promise.reject(response);
  } else {
    let response = {
      status: 444,
      data: {
        code: 444,
        message: "请求网络超时，请稍后重试"
      }
    };
    errorHandle(response.status, response.data.message);
    return Promise.reject(response);
  }
});
const _axios = {
  /**
   * @param url 除域名外的请求地址
   * @param data 请求参数 即？后拼接的请求参数通过json对象的形式传入即可
   * @param sucFun 请求成功后回调方法
   * @param failFun 请求失败后回调方法
   * @param showErrorMsg 是否土司接口请求失败后的错误信息
   */
  get(url, params, sucFun, failFun, showErrorMsg = true) {
    store.commit('SHOW_LOADING');
    let headers = {
      token: Vue.prototype.apiObj.token,
      clientId: globalConfig.clientId
    };
    headers = cryptoJS.sign(headers, url);
    if (globalFunction.getObjectLength(params)) {
      params = globalFunction.jsonToUrlParams(params);
    } else {
      params = null;
    }
    params = encodeURIComponent(cryptoJS.base64.en(cryptoJS.aes.en(params)));
    axiosInstance({
      method: "get",
      url: baseUrlC + url,
      headers,
      params
    }).then(function (res) {
      store.commit('HIDE_LOADING');
      if (sucFun) {
        sucFun(cryptoJS.aes.de(cryptoJS.base64.de(res.data)));
      }
    }).catch(function (error) {
      store.commit('HIDE_LOADING');
      try {
        if (showErrorMsg) {
          _Toast.fail(cryptoJS.aes.de(cryptoJS.base64.de(error.data.message)));
        }
        failFun(cryptoJS.aes.de(cryptoJS.base64.de(error.data)));
      } catch (e) {}
    });
  },
  getNoLoaded(url, params, sucFun, failFun, showErrorMsg = true) {
    let headers = {
      token: Vue.prototype.apiObj.token,
      clientId: globalConfig.clientId
    };
    headers = cryptoJS.sign(headers, url);
    if (globalFunction.getObjectLength(params)) {
      params = globalFunction.jsonToUrlParams(params);
    } else {
      params = null;
    }
    params = encodeURIComponent(cryptoJS.base64.en(cryptoJS.aes.en(params)));
    axiosInstance({
      method: "get",
      url: baseUrlC + url,
      headers,
      params
    }).then(function (res) {
      if (sucFun) {
        sucFun(cryptoJS.aes.de(cryptoJS.base64.de(res.data)));
      }
    }).catch(function (error) {
      try {
        if (showErrorMsg) {
          _Toast.fail(cryptoJS.aes.de(cryptoJS.base64.de(error.data.message)));
        }
        failFun(cryptoJS.aes.de(cryptoJS.base64.de(error.data)));
      } catch (e) {}
    });
  },
  post(url, data, sucFun, failFun, showErrorMsg = true) {
    store.commit('SHOW_LOADING');
    let headers = {
      token: Vue.prototype.apiObj.token,
      clientId: globalConfig.clientId,
      'Content-Type': 'application/json'
    };
    headers = cryptoJS.sign(headers, url);
    data = cryptoJS.base64.en(cryptoJS.aes.en(data));
    axiosInstance({
      method: "post",
      url: baseUrlC + url,
      headers,
      data
    }).then(function (res) {
      store.commit('HIDE_LOADING');
      if (sucFun) {
        sucFun(cryptoJS.aes.de(cryptoJS.base64.de(res.data)));
      }
    }).catch(function (error) {
      store.commit('HIDE_LOADING');
      try {
        if (showErrorMsg) {
          _Toast.fail(cryptoJS.aes.de(cryptoJS.base64.de(error.data.message)));
        }
        failFun(cryptoJS.aes.de(cryptoJS.base64.de(error.data)));
      } catch (e) {}
    });
  },
  postNoLoaded(url, data, sucFun, failFun, showErrorMsg = true) {
    let headers = {
      token: Vue.prototype.apiObj.token,
      clientId: globalConfig.clientId,
      'Content-Type': 'application/json'
    };
    headers = cryptoJS.sign(headers, url);
    data = cryptoJS.base64.en(cryptoJS.aes.en(data));
    axiosInstance({
      method: "post",
      url: baseUrlC + url,
      headers,
      data
    }).then(function (res) {
      if (sucFun) {
        sucFun(cryptoJS.aes.de(cryptoJS.base64.de(res.data)));
      }
    }).catch(function (error) {
      try {
        if (showErrorMsg) {
          _Toast.fail(cryptoJS.aes.de(cryptoJS.base64.de(error.data.message)));
        }
        failFun(cryptoJS.aes.de(cryptoJS.base64.de(error.data)));
      } catch (e) {}
    });
  },
  getDownload(url, params, sucFun, failFun, showErrorMsg = true) {
    store.commit('SHOW_LOADING');
    let headers = {
      token: Vue.prototype.apiObj.token,
      clientId: globalConfig.clientId
    };
    headers = cryptoJS.sign(headers, url);
    params = cryptoJS.base64.en(cryptoJS.aes.en(params));
    axiosInstance({
      method: "get",
      url: baseUrlC + url,
      headers,
      params,
      responseType: 'blob'
    }).then(function (res) {
      store.commit('HIDE_LOADING');
      if (sucFun) {
        sucFun(cryptoJS.aes.de(cryptoJS.base64.de(res.data)));
      }
    }).catch(function (error) {
      store.commit('HIDE_LOADING');
      try {
        if (showErrorMsg) {
          _Toast.fail(cryptoJS.aes.de(cryptoJS.base64.de(error.data.message)));
        }
        failFun(cryptoJS.aes.de(cryptoJS.base64.de(error.data)));
      } catch (e) {}
    });
  }
};
export default _axios;