export default [{
  path: '/information/list',
  name: 'InformationIndex',
  meta: {
    title: '资讯列表',
    keepAlive: false
  },
  component: () => import('@/views/information/list.vue')
}, {
  path: '/information/center',
  name: 'InformationCenter',
  meta: {
    title: '资讯中心',
    keepAlive: true
  },
  component: () => import('@/views/information/center.vue')
}, {
  path: '/information/search',
  redirect: '/information/search/all',
  name: 'informationSearch',
  meta: {
    title: '今日热搜'
  },
  component: () => import('@/views/information/search/index.vue'),
  children: [{
    path: 'all',
    name: 'all',
    component: () => import('@/views/information/search/all.vue')
  }, {
    path: 'article',
    name: 'article',
    component: () => import('@/views/information/search/article.vue')
  }, {
    path: 'video',
    name: 'video',
    component: () => import('@/views/information/search/video.vue')
  }, {
    path: 'news',
    name: 'news',
    component: () => import('@/views/information/search/news.vue')
  }]
}, {
  path: '/information/detail',
  name: 'InformationDetail',
  meta: {
    title: '资讯详情',
    keepAlive: false
  },
  component: () => import('@/views/information/detail.vue')
}, {
  path: '/information/videoList',
  name: 'InformationVideoList',
  meta: {
    title: '看一看',
    keepAlive: true
  },
  component: () => import('@/views/information/videoList.vue')
}, {
  path: '/information/preview',
  name: 'InformationPreview',
  meta: {
    title: '文章预览',
    keepAlive: false
  },
  component: () => import('@/views/information/preview.vue')
}];