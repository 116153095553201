export default [{
  path: '/policyHosting',
  name: 'policyHosting',
  component: () => import('@/views/policyHosting/uploadPolicy/index'),
  meta: {
    title: '保单保管箱',
    keepAlive: true
  }
}, {
  path: '/policyHosting/uploadPolicy/selectFamily',
  name: 'selectFamily',
  component: () => import('@/views/policyHosting/uploadPolicy/selectFamily.vue'),
  meta: {
    title: '选择好友家庭',
    keepAlive: true
  }
}, {
  path: '/policyHosting/detail',
  name: 'detail',
  component: () => import('@/views/policyHosting/detail.vue'),
  meta: {
    title: '保单详情',
    keepAlive: true
  }
}, {
  path: '/policyHosting/moreMembers',
  name: 'moreMembers',
  component: () => import('@/views/policyHosting/moreMembers.vue'),
  meta: {
    title: '家庭关系',
    keepAlive: true
  }
}, {
  path: '/policyHosting/customerList',
  name: 'customerList',
  component: () => import('@/views/policyHosting/customerList.vue'),
  meta: {
    title: '好友列表',
    keepAlive: true
  }
}, {
  path: '/policyHosting/uploadPolicy/failureDetail',
  name: 'failureDetail',
  component: () => import('@/views/policyHosting/uploadPolicy/failureDetail.vue'),
  meta: {
    title: '保单解析失败详情',
    keepAlive: true
  }
}, {
  path: '/policyHosting/uploadPolicy/upload',
  name: 'upload',
  component: () => import('@/views/policyHosting/uploadPolicy/upload.vue'),
  meta: {
    title: '上传保单',
    keepAlive: true
  }
}, {
  path: '/policyHosting/uploadPolicy/policyShare',
  name: 'policyShare',
  component: () => import('@/views/policyHosting/uploadPolicy/policyShare.vue'),
  meta: {
    title: '分享保单保管箱',
    keepAlive: true
  }
}, {
  path: '/policyHosting/uploadPolicy/policyCase',
  name: 'policyCase',
  component: () => import('@/views/policyHosting/uploadPolicy/policyCase.vue'),
  meta: {
    title: '案例',
    keepAlive: true
  }
}, {
  path: '/policyHosting/inventory',
  name: 'inventory',
  component: () => import('@/views/policyHosting/inventory.vue'),
  meta: {
    title: '保单清单',
    keepAlive: true
  }
}];