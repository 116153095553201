export default [{
  path: '/my/index',
  name: 'myIndex',
  meta: {
    title: '我的',
    keepAlive: true
  },
  component: () => import('@/views/my/index.vue')
}, {
  path: '/pdf/index',
  name: 'pdfIndex',
  meta: {
    title: '预览',
    keepAlive: true
  },
  component: () => import('@/views/pdf/index.vue')
}];