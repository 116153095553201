export default [{
  path: '/card/agent',
  name: 'cardAgent',
  meta: {
    title: '服务人员首页',
    keepAlive: false
  },
  component: () => import('@/views/card/index.vue')
}, {
  path: '/card/editor',
  name: 'cardEditor',
  meta: {
    title: '服务人员首页',
    keepAlive: false
  },
  component: () => import('@/views/card/editor.vue')
}];