export default [{
  path: '/pk/index',
  name: 'pkIndex',
  meta: {
    title: '好友对战',
    keepAlive: false
  },
  component: () => import('@/views/pk/index.vue')
}, {
  path: '/pk/result',
  name: 'pkResult',
  meta: {
    title: '好友对战',
    keepAlive: false
  },
  component: () => import('@/views/pk/result.vue')
}, {
  path: '/pk/task',
  name: 'pkTask',
  meta: {
    title: '任务列表',
    keepAlive: false
  },
  component: () => import('@/views/pk/task.vue')
}, {
  path: '/pk/share',
  name: 'pkShare',
  meta: {
    title: '分享',
    keepAlive: false
  },
  component: () => import('@/views/pk/share.vue')
}];