import api from './index';
// axios
import request from '@/utils/request';
import globalConfig from "@/config";

// 登录
export function login(query, sucFun) {
  request.postNoLoaded(api.Login, query, sucFun);
}

//用户授权
export function codeInfoLogin(appId, query, sucFun) {
  request.getNoLoaded(`/wxmp/codeinfo/${appId}/login`, query, sucFun);
}

// 注册
export function register(query, sucFun) {
  request.postNoLoaded(api.Register, query, sucFun);
}

// 发送验证码
export function smsCode(query, sucFun) {
  request.postNoLoaded(api.SmsCode, query, sucFun);
}

//好友列表
export function getFriendList(query, sucFun) {
  request.getNoLoaded(`/user/friends`, query, sucFun);
}

// 用户信息
export function loginCheck(query, sucFun) {
  request.getNoLoaded('/user/login/check', query, sucFun);
}

//获取指定用户对应服务人员信息
export function getUserAgent(query, sucFun) {
  request.getNoLoaded('/user/agent/list', query, sucFun);
}

// 注册
export function activationEquity(query, sucFun) {
  request.post('/user/equity/activation', query, sucFun);
}

/**
 * 查询我的权限
 */
export function queryMyEquityList(query, sucFun) {
  request.post(`/user/equity/query/my/list`, query, sucFun);
  // request.post(`http://localhost:8080/equity/query/my/list`,query,sucFun)
}

/**
 * 查询我的权限
 */
export function queryMyReportList(query, sucFun) {
  request.post(`/user/equity/query/my/report/list`, query, sucFun);
  // request.post(`http://localhost:8080/equity/query/my/list`,query,sucFun)
}