/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [{
  path: '/my/index',
  name: 'myIndex',
  meta: {
    title: '我的',
    keepAlive: false
  },
  component: () => import('@/views/my/index.vue')
}];