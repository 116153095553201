import Router from 'vue-router';
import { constantRouterMap } from './router.config';
import policySafeBox from './policySafeBox'; //保单保管箱
import activity from './activity'; //活动
import works from './works'; //作品
import tribal from './tribal'; //作品
import gift from './gift'; //礼物盒子
import pk from './pk'; //礼物盒子
import calculator from './calculator'; //计划书
import circle from './circle'; //礼物盒子
import community from './community'; //社区
import card from './card'; //名片
import information from './information'; //资讯
import health from './health'; //健康
import my from './my'; //我的
import task from './task'; //任务
import brc from './brc'; //博尔诚对接

// hack router push callback
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
const createRouter = () => new Router({
  // mode: 'history', // 如果你是 history模式 需要配置vue.config.js publicPath
  base: process.env.BASE_URL,
  scrollBehavior: () => ({
    y: 0
  }),
  routes: [...constantRouterMap, ...policySafeBox, ...activity, ...works, ...tribal, ...gift, ...pk, ...calculator, ...circle, ...community, ...card, ...information, ...health, ...my, ...task, ...brc]
});
const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;