var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShow
    ? _c("div", { staticClass: "register-dialog-container" }, [
        _vm.isShow ? _c("div", { staticClass: "meng" }) : _vm._e(),
        _vm.isShow
          ? _c(
              "div",
              { staticClass: "flexBox flex-center layerBox w100 h100" },
              [
                _c("div", { staticClass: "content t-center" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "flexBox btnList borderT" }, [
                    _c(
                      "div",
                      {
                        staticClass: "sure t-center",
                        on: {
                          click: function($event) {
                            return _vm.goRegister()
                          }
                        }
                      },
                      [_vm._v("去绑定")]
                    )
                  ])
                ])
              ]
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("您还未用户绑定"),
      _c("br"),
      _vm._v("请先进行用户绑定")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }