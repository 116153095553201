export default [{
  path: '/gift/index',
  name: 'giftIndex',
  meta: {
    title: '礼物盒子',
    keepAlive: false
  },
  component: () => import('@/views/gift/index.vue')
}, {
  path: '/gift/give',
  name: 'giftGive',
  meta: {
    title: '赠送记录',
    keepAlive: false
  },
  component: () => import('@/views/gift/give.vue')
}, {
  path: '/gift/detail',
  name: 'giftDetail',
  meta: {
    title: '礼物详情',
    keepAlive: false
  },
  component: () => import('@/views/gift/detail.vue')
}, {
  path: '/gift/lottery',
  name: 'giftLottery',
  meta: {
    title: '活动抽奖',
    keepAlive: false
  },
  component: () => import('@/views/gift/lottery.vue')
}, {
  path: '/gift/win',
  name: 'giftWin',
  meta: {
    title: '活动中奖',
    keepAlive: false
  },
  component: () => import('@/views/gift/win.vue')
}];