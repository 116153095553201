import { reqWxNameHead } from "@/api/publicApi";
import * as qiniu from "qiniu-js";
import { getQiniuToken } from "@/api/utils/index";
import globalFunction from './globalFunction';
const productFunction = {
  /**
   * 保存用户操作记录new
     actionCode:'',      //动态代码
     duration:'',        //阅读时长
     businessId:'',      //业务主键  活动id
     remark: :'',        //记录标题类信息
  */
  saveMedicalCustomerActivityNew(params) {
    if (params.duration) params.duration = Math.round(params.duration);
    if (globalFunction.getQueryString('taskId')) params.taskId = globalFunction.getQueryString('taskId');
    return new Promise((resolve, reject) => {
      this.axios.postNoLoaded('/useraction/action/save', params, json => {
        resolve(json.code);
      });
    });
  },
  /**
   * 获取wx用户姓名和头像地址
   */
  getWxNameHead(callback) {
    if (this.apiObj.wxName && this.apiObj.wxHeadUrl) {
      if (callback) {
        callback(this.apiObj.wxName, this.apiObj.wxHeadUrl);
      }
      return;
    }
    reqWxNameHead({}, this.apiObj.appIdWX, this.apiObj.userOpenId).then(json => {
      //函数体
      if (json.code == 200) {
        let nickname = json.data.nickname;
        let url = json.data.headImgUrl;
        this.apiObj.wxName = nickname;
        this.apiObj.wxHeadUrl = url;
        if (callback) {
          callback(nickname, url);
        }
      }
    });
  },
  /**
   * 保存分享
   * businessId:'',      //业务主键  活动id
     shareCode: :'',     //分享码
     parentShareCode,    //转发分享码(链接上带的分享码)
     actionCode:'',      //行为记录码，必传
     openUrl: :'',       //分享打卡地址 必传
     shareTitle:'',      //分享标题 必传
     img: '',            //分享小图标
     shareDesc: :'',     //分享描述
     remark:'',          //备注
     data:'',            //分享时前端需要的数据 (纯前自用)
     reportShareCode     //圈子管理用
  */
  saveShareCode(parentThis, params) {
    if (!params.shareDesc) params.shareDesc = params.shareTitle;
    if (parentThis.globalFunction.getQueryString('taskId')) params.taskId = parentThis.globalFunction.getQueryString('taskId');
    return new Promise((resolve, reject) => {
      parentThis.axios.postNoLoaded('/healthshare/share/save', params, json => {
        if (json.code == 200) {
          resolve(json);
        } else {
          reject(json);
        }
      }, true);
    });
  },
  /**
   * 打开分享
   * params:'', 参数 :分享码,行为码
  */
  getOpenShare(parentThis, params) {
    return new Promise((resolve, reject) => {
      parentThis.axios.postNoLoaded(`/healthshare/share/open/save`, params, json => {
        if (json.code == 200) {
          resolve(json);
        } else {
          reject(json);
        }
      }, true);
    });
  },
  /**
   * 上传到七牛云
   */
  uploadFileToQiNiu(parentThis, type, e) {
    let that = parentThis;
    let tempUrl = '';
    that.qiniuInfo = {};
    let tokenParams = {
      uploadType: type
    };
    if (!e.file) {
      if (!e.target.files.length) {
        parentThis.$toast.clear();
        return;
      }
    }
    parentThis.$toast.loading({
      message: '上传中...',
      forbidClick: true,
      duration: 0
    });
    // if(that.qiniuInfo.token){

    // }else{
    // }
    return new Promise((resolve, reject) => {
      getQiniuToken(tokenParams, json => {
        if (json.code == 200) {
          that.qiniuInfo = json.data;
          let file = '';
          e.file ? file = e.file : file = e.target.files[0];
          let videoType = file.type.split('/')[1];
          if (that.qiniuInfo.fileTypes == 'video/*;image/*') {} else if (that.qiniuInfo.fileTypes == 'video/*' || that.qiniuInfo.fileTypes == 'image/*') {
            if (that.qiniuInfo.fileTypes.indexOf(file.type.split('/')[0]) == -1) {
              that.$toast('文件格式不正确');
              return;
            }
          } else {
            if (that.qiniuInfo.fileTypes.indexOf(file.type.split('/')[1]) == -1) {
              that.$toast('文件格式不正确');
              return;
            }
          }
          if (file.size / 1024 / 1024 > that.qiniuInfo.maxFileSize / 1024) {
            that.$toast('视频大小超出限制');
            return;
          }
          let token = that.qiniuInfo.uploadToke;
          var config = {
            useCdnDomain: true
          };
          var putExtra = {
            fname: "",
            params: {},
            mimeType: null
          };
          // this.isShowVideoMusk = true
          let key = '';
          // if(this.isAgentUpload){
          //   key = `${that.qiniuInfo.filePath}${this.globalFunction.Mathuuid(32)}.${videoType}`
          // }else{
          key = `${that.qiniuInfo.filePath}${that.qiniuInfo.fileName}.${videoType}`;
          // }
          var observable = qiniu.upload(file, key, token, putExtra, config);
          var observer = {
            next(res) {
              that.plan = res.total.percent.toFixed();
              this.filePercent = parseInt(res.total.percent);
            },
            error(err) {
              parentThis.$toast.clear();
              parentThis.$toast(err.message);
              reject(err);
            },
            complete(res) {
              tempUrl = `http://cdn.e-tianrong.com/${res.key}`;
              resolve(tempUrl);
              parentThis.$toast.clear();
              // that.params.videoUrl = `http://cdn.e-tianrong.com/${key}`
              // that.playerOptions.sources[0].src = `http://cdn.e-tianrong.com/${key}`
              // that.isShowVideoMusk = false
              // // that.findvideocover(that.params.videoUrl).then((res)=>{
              // //   that.params.bannerUrl = res
              // // })
              // getUrl(res.key).then(res => {//请求接口返回地址
              //   // that.videoUrl = res.data.data;
              //   tempUrl = res.data.data
              //   console.log(tempUrl)
              //   return tempUrl
              //   that.bannerUrl = this.videoUrl + "?vframe/png/offset/1";
              // });
            }
          };

          var subscription = observable.subscribe(observer); // 上传开始
        } else {
          that.$toast(json.message);
        }
      });
    });
    // tokenParams.uploadType = 'WORKS_IMAGE'
  }
};

export default productFunction;