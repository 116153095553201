import componentRegisterDialog from './registerDialog.vue';
// 需要在此对象上写一个install方法
let registerDialog = {};
registerDialog.install = Vue => {
  let V = Vue.extend(componentRegisterDialog),
    vm = new V(),
    registerDialogDiv = document.createElement('div');
  vm.$mount(registerDialogDiv);
  document.body.appendChild(vm.$el);
  Vue.prototype.$registerDialog = (isShow, userType, agencyInfo) => {
    vm.isShow = isShow;
    vm.userType = userType || '';
    vm.agencyInfo = agencyInfo || '';
    vm.in = Vue;
  };
};
export default registerDialog;