export default [{
  path: '/tribal/index',
  name: 'tribalIndex',
  meta: {
    title: '部落首页',
    keepAlive: true
  },
  component: () => import('@/views/tribal/index.vue')
}, {
  path: '/tribal/create',
  name: 'tribalCreate',
  meta: {
    title: '创建部落',
    keepAlive: true
  },
  component: () => import('@/views/tribal/create.vue')
}, {
  path: '/tribal/share',
  name: 'tribalShare',
  meta: {
    title: '分享我的部落',
    keepAlive: true
  },
  component: () => import('@/views/tribal/share.vue')
}];