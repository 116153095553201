export default [{
  path: '/circle/index',
  name: 'circleIndex',
  meta: {
    title: '今日动态',
    keepAlive: false
  },
  component: () => import('@/views/circle/index.vue')
}, {
  path: '/circle/hot',
  name: 'circleHot',
  meta: {
    title: '今日热点',
    keepAlive: false
  },
  component: () => import('@/views/circle/hot.vue')
}, {
  path: '/circle/detail',
  name: 'circleDetail',
  meta: {
    title: '数据详情',
    keepAlive: false
  },
  component: () => import('@/views/circle/detail.vue')
}, {
  path: '/circle/board',
  name: 'circleBoard',
  meta: {
    title: '数据看板',
    keepAlive: false
  },
  component: () => import('@/views/circle/board.vue')
}, {
  path: '/circle/readList',
  name: 'circleReadList',
  meta: {
    title: '阅读列表',
    keepAlive: false
  },
  component: () => import('@/views/circle/readList.vue')
}, {
  path: '/circle/myShare',
  name: 'circleMyShare',
  meta: {
    title: '我分享的内容',
    keepAlive: true
  },
  component: () => import('@/views/circle/myShare.vue')
}, {
  path: '/circle/friendList',
  name: 'circleFriendList',
  meta: {
    title: '好友列表',
    keepAlive: true
  },
  component: () => import('@/views/circle/friendList.vue')
}, {
  path: '/circle/friendListTemp',
  name: 'circleFriendListTemp',
  meta: {
    title: '好友列表',
    keepAlive: true
  },
  component: () => import('@/views/circle/friendListTemp.vue')
}, {
  path: '/circle/friendDetail',
  name: 'circleFriendDetail',
  meta: {
    title: '好友详情',
    keepAlive: false
  },
  component: () => import('@/views/circle/friendDetail.vue')
}, {
  path: '/circle/userInfo',
  name: 'circleUserInfo',
  meta: {
    title: '详细信息',
    keepAlive: false
  },
  component: () => import('@/views/circle/userInfo.vue')
}, {
  path: '/circle/attended',
  name: 'circleAttended',
  meta: {
    title: '全部参加过得活动',
    keepAlive: false
  },
  component: () => import('@/views/circle/attended.vue')
}, {
  path: '/circle/readFriendList',
  name: 'circleReadFriendList',
  meta: {
    title: '阅读好友列表',
    keepAlive: false
  },
  component: () => import('@/views/circle/readFriendList.vue')
}, {
  path: '/circle/share',
  name: 'circleShare',
  meta: {
    title: '送礼物',
    keepAlive: false
  },
  component: () => import('@/views/circle/share.vue')
}, {
  path: '/demo',
  name: 'demo',
  meta: {
    title: 'demo',
    keepAlive: false
  },
  component: () => import('@/views/demo/webgl.vue')
}, {
  path: '/customerDynamic/list',
  name: 'customerDynamicList',
  meta: {
    title: '获客动态',
    keepAlive: false
  },
  component: () => import('@/views/circle/customerDynamic/list.vue')
}, {
  path: '/customerDynamic/detail',
  name: 'customerDynamicDetail',
  meta: {
    title: '获客动态详情',
    keepAlive: false
  },
  component: () => import('@/views/circle/customerDynamic/detail.vue')
}];