import getters from './getters';
import app from './modules/app';
import loading from './modules/loading';
import keepAlive from './modules/keepAlive';
const store = new Vuex.Store({
  modules: {
    app,
    loading,
    keepAlive
  },
  getters
});
export default store;