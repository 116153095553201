var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("Loading", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.$store.state.loading.Loading,
            expression: "$store.state.loading.Loading"
          }
        ]
      }),
      _c(
        "keep-alive",
        { attrs: { include: _vm.$store.state.keepAlive.cachedRouteNames } },
        [_c("router-view")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }