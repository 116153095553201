import request from "@/utils/request.js";
export function uploadFile(query, type, sunFun) {
  request.post(`/healthaccount/public/uploadFile?type=${type}`, query, sunFun);
}
/**
 * 获取验证码
 * @param {*} query
 * @returns
 */
export function getSmsCode(query, sunFun) {
  request.post(`/user/smsCode/create`, query, sunFun);
}

/**
 * 注册
 * @param {*} query
 * @returns
 */
export function register(query, sunFun) {
  request.post(`/healthaccount/customer/register`, query, sunFun);
}

/**
 * 获取头像昵称
 */
export function reqWxNameHead(query, appIdWX, userOpenId) {
  return new Promise(resolve => {
    request.get(`/mp/user/${appIdWX}?openId=${userOpenId}`, query, json => {
      resolve(json);
    });
  });
}

/**
 * 公共轮播图
 */
export function carouselList(query, sunFun) {
  request.post(`/healthbase/carousel/list`, query, sunFun);
}

// 获取守护值和健康豆
export function getGuardIndex(query, sucFun) {
  request.getNoLoaded('/memberpoints/guard/getGuardIndex', query, sucFun);
}

// 获取分享id
export function shareSetting(relationId, query, sucFun) {
  request.getNoLoaded(`/healthbase/share/settings/${relationId}`, query, sucFun);
}