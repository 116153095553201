const state = {
  Loading: false
};
const mutations = {
  SHOW_LOADING(state) {
    state.Loading = true;
  },
  HIDE_LOADING(state) {
    state.Loading = false;
  }
};
const actions = {
  showLoading({
    commit
  }) {
    commit('SHOW_LOADING');
  },
  hideLoading({
    commit
  }) {
    commit('HIDE_LOADING');
  }
};
export default {
  state,
  mutations,
  actions
};